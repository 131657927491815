import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { virtualPlaceNavs } from "../../config/virtualPlaceNavigation";
import useResize from "../../hooks/useResize";
import define from "../../config/define";
import { lobbySet } from "../../query/queries";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import { ModalPortalContext } from "../../context/ModalContext";

const Video = styled.div`
  width: 470px;
  height: 260px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 275px;
  display: block;
  cursor: pointer;
  left: 725px;
  z-index: 2;
  color: transparent;
`;
const ConferenceHallPusher = styled.div`
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  width: 500px;
  height: 110px;
  top: 550px;
  left: 710px;
  display: block;
  cursor: pointer;
  .pulsing_animation {
    position: relative;
    left: 50%;
    top: 45%;
  }
`;
const LeftHorizontalBanner = styled.div`
  width: 245px;
  height: 95px;
  font-size: 25px;
  position: absolute;
  top: 50px;
  display: block;
  cursor: pointer;
  left: 424px;
  z-index: 2;
  color: transparent;

  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightHorizontalBanner = styled.div`
  width: 245px;
  height: 96px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 48px;
  display: block;
  cursor: pointer;
  left: 1248px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const LeftBigBanner = styled.div`
  width: 465px;
  height: 300px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 255px;
  display: block;
  cursor: pointer;
  left: 135px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightBigBanner = styled.div`
  width: 465px;
  height: 300px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 255px;
  display: block;
  cursor: pointer;
  left: 1320px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const LeftVerticalBanner = styled.div`
  width: 80px;
  height: 115px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 660px;
  display: block;
  cursor: pointer;
  left: 660px;
  z-index: 2;
  color: transparent;

  // img {
  //   object-fit: fill;
  //   width: 100%;
  //   height: 100%;
  // }

  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightVerticalBanner = styled.button`
  width: 80px;
  height: 115px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 660px;
  display: block;
  cursor: pointer;
  left: 1178px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: absolute;
    left: 40%;
  }
`;

const NetworkingLoungeBtn = styled.div`
  width: 288px;
  height: 215px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 785px;
  display: block;
  cursor: pointer;
  left: 157px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;

const Lobby2DStyle1 = ({
  isLang,
  loginType,
  names,
  error: lobbySetError,
  lobbySet: lobbyData,
  eventInfoCompleted,
  setSelectedSession,
  setStamp,
  selectedPT,
  showFuncCollection,
  navigationSet,
}) => {
  const contentWrapRef = useRef(null);
  const { contentWrapStyle, contentResizeStyle } = useResize(contentWrapRef);
  const history = useHistory();
  // console.log("lobbyData", lobbyData);
  const {
    left_horizontal_banner,
    left_big_banner,
    left_vertical_banner,
    right_big_banner,
    right_horizontal_banner,
    right_vertical_banner,
    video_url,
    video_cover,
    right_horizontal_banner_type,
    right_vertical_banner_type,
    right_big_banner_type,
    left_horizontal_banner_type,
    left_big_banner_type,
    left_vertical_banner_type,
    right_horizontal_banner_val,
    right_vertical_banner_val,
    right_big_banner_val,
    left_horizontal_banner_val,
    left_big_banner_val,
    left_vertical_banner_val,
  } = lobbyData;
  const { triggerModal, close } = useContext(ModalPortalContext);

  const runByBannerType = (somethings_banner_type, somethings_banner_val) => {
    if (somethings_banner_type === "event") {
      const eventUrl = somethings_banner_val;
      const type = somethings_banner_type;
      // showFuncCollection?.showEvent(eventUrl);// 이벤트 모달은 이미지용
      showFuncCollection?.showSurvey(eventUrl, type); // 서베이 모달은 임베디드 url용
    } else if (somethings_banner_type === "survey") {
      const surveyUrl = somethings_banner_val;
      showFuncCollection?.showSurvey(surveyUrl);
    } else if (somethings_banner_type === "membership") {
      showFuncCollection?.showMembership();
    } else if (somethings_banner_type === "replay") {
      showFuncCollection?.showReplay();
    } else if (somethings_banner_type === "vod") {
      showFuncCollection?.showVod();
    } else if (somethings_banner_type === "networking") {
      showFuncCollection?.showNetworking();
    } else if (somethings_banner_type === "program") {
      showFuncCollection?.showProgramInfo();
    } else if (somethings_banner_type === "link") {
      showFuncCollection?.goTo(somethings_banner_val);
    }
  };

  const videoOpener = async () =>
    triggerModal({
      bool: true,
      props: {
        selectedVod: {
          title: "SCREEN VIDEO",
          url: video_url,
        },
      },
      content: {
        title: "",
        Component: (props) => {
          return <VideoOnly {...props} />;
        },
        hasOwnContainer: true,
      },
    });

  const PulsingAnimation = () => (
    <div className="pulsing_animation">
      <a href="#" className="blob-btn">
        <div className="ring">
          <span className="pulse pulse--1"></span>
          <span className="pulse pulse--2"></span>
        </div>
      </a>
    </div>
  );

  return (
    <>
      <div
        style={{
          height: "100%",
          background:
            "linear-gradient(rgb(4, 16, 21) 0%, rgb(12, 41, 54) 100%)",
        }}
      >
        <div
          className="lobby wrapper login-wrap"
          style={{
            height: "100%",
            position: "relative",
            background: "none",
          }}
        >
          <div className="content-wrap" style={contentWrapStyle}>
            <div
              className="canvas-wrap"
              ref={contentWrapRef}
              style={contentResizeStyle}
            >
              <img
                alt="로비 배경 화면"
                src={define.RESOURCE.Lobby.Style1}
                className="lobby__background"
              />
              <ConferenceHallPusher
                title="Conference Hall"
                type="button"
                onClick={() => {
                  history.push(virtualPlaceNavs.conference_name.path);
                }}
              >
                {PulsingAnimation()}
              </ConferenceHallPusher>
              <Video>
                {/* <img alt="video cover" /> */}
                <iframe
                  src={video_url}
                  // src="https://player.vimeo.com/video/631527659?controls=0&autoplay=1&loop=1&autopause=0&muted=1"
                  style={{ width: "100%", height: "100%" }}
                  allow="camera; microphone; autoplay; fullscreen"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
                <div
                  style={{
                    position: "relative",
                    top: "-265px",
                    height: "270px",
                    zIndex: "2",
                  }}
                  onClick={videoOpener}
                />
              </Video>
              <LeftHorizontalBanner
                title="LeftHorizontalBanner"
                onClick={() =>
                  runByBannerType(
                    left_horizontal_banner_type,
                    left_horizontal_banner_val
                  )
                }
              >
                <img
                  alt="좌측 상단 배너"
                  src={left_horizontal_banner}
                  width="100%"
                  height="100%"
                />
                {PulsingAnimation()}
              </LeftHorizontalBanner>
              <LeftBigBanner
                title="LeftBigBanner"
                onClick={() =>
                  runByBannerType(left_big_banner_type, left_big_banner_val)
                }
              >
                <img
                  alt="좌측 중간 배너"
                  src={left_big_banner}
                  width="100%"
                  height="100%"
                />
                {PulsingAnimation()}
              </LeftBigBanner>
              <LeftVerticalBanner
                title="LeftVerticalBanner"
                onClick={() =>
                  runByBannerType(
                    left_vertical_banner_type,
                    left_vertical_banner_val
                  )
                }
              >
                <img
                  alt="좌측 하단 배너"
                  src={left_vertical_banner}
                  width="100%"
                  height="100%"
                />
                {PulsingAnimation()}
              </LeftVerticalBanner>
              <RightHorizontalBanner
                title="RightHorizontalBanner"
                onClick={() =>
                  runByBannerType(
                    right_horizontal_banner_type,
                    right_horizontal_banner_val
                  )
                }
              >
                <img
                  alt="우측 상단 배너"
                  src={right_horizontal_banner}
                  width="100%"
                  height="100%"
                />
                {PulsingAnimation()}
              </RightHorizontalBanner>
              <RightBigBanner
                title="RightBigBanner"
                onClick={() =>
                  runByBannerType(right_big_banner_type, right_big_banner_val)
                }
              >
                <img
                  alt="우측 중간 배너"
                  src={right_big_banner}
                  width="100%"
                  height="100%"
                />
                {PulsingAnimation()}
              </RightBigBanner>

              <RightVerticalBanner
                title="RightVerticalBanner"
                onClick={() =>
                  runByBannerType(
                    right_vertical_banner_type,
                    right_vertical_banner_val
                  )
                }
                disabled={navigationSet.vod_modal_yn !== "Y"}
              >
                <img
                  alt="우측 하단 배너"
                  src={right_vertical_banner}
                  width="100%"
                  height="100%"
                />

                {navigationSet.vod_modal_yn === "Y" && PulsingAnimation()}
              </RightVerticalBanner>

              {navigationSet.networking_modal_yn === "Y" && (
                <NetworkingLoungeBtn
                  title="NetworkingLoungeBtn"
                  onClick={() => runByBannerType("networking", "")}
                >
                  {PulsingAnimation()}
                </NetworkingLoungeBtn>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lobby2DStyle1;
