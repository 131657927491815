import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { navSet } from "../../query/queries";
import { PUBLIC_URL } from "../../config/define";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import MyInfo from "../common/modal/contents/MyInfo";
import UserGuide from "../common/modal/contents/UserGuide";
import StampTour from "../stamp/StampTour";
import ProgramInfo from "../common/modal/contents/programInfo/ProgramInfo";
import UserPolicy from "../common/modal/contents/registration/UserPolicy";
import NavItem from "./NavItem";
import CommonNav from "./common/CommonNav";
import CommonNav2D from "./common/CommonNav2D";
import ChannelService from "../../util/ChannelService";
import util from "../../util";
import styled from "styled-components";
import SessionReplay from "../common/modal/contents/SessionReplay";
import VideoOnly from "../common/modal/contents/video/VideoOnly";
import NetworkLoungeTable from "../lounge/NetworkLoungeTable";
import MemberShip2D from "../lounge/MemberShip";
import VOD from "../theater/VOD";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const languageOptions = {
  kr: "한국어",
  en: "English",
};
const commonNavMenu = {
  program: {
    en: "Conference Schedule",
    kr: "컨퍼런스 스케줄",
  },
  replay: {
    en: "VOD Room",
    kr: "지난 세미나 시청",
  },
  vod: {
    en: "Training Center",
    kr: "교육 영상",
  },
  networking: {
    en: "Networking Lounge",
    kr: "네트워킹 라운지",
  },
  membership: {
    en: "Membership",
    kr: "맴버십",
  },
};

const dimensionType = ["d3", "d2"];
// const dimension = "d2";

// todo 언어 변환 시 네비게이션 동일 변환
const Navbar = ({
  loginType,
  mainLang,
  languages,
  privacy_consent,
  user_consent,
  navigationSet,
  scheduleList,
  refetchEventInfo,
  replayList,
  socket,
  theaterCategoryList,
  loginLogo,
  showFuncCollection,
}) => {
  const {
    showLoginModal,
    getLoginStatus,
    getUser,
    logout: _logout,
    refetch,
  } = useContext(UserLoginContext);
  const { triggerModal, openAlert, close } = useContext(ModalPortalContext);
  const [language, setLanguage] = useState("");
  const [isClicked, setIsClicked] = useState(true);
  const [commonNav, setCommonNav] = useState([]);
  const isLogin = getLoginStatus();
  const user = getUser();
  const navNames = navigationSet?.names ? Object.keys(navigationSet.names) : [];
  const toggle = () => setIsClicked((prev) => !prev);
  const history = useHistory();
  const handleRouting = (path) => {
    history.push(path);
  };
  const location = useLocation();

  const showTrainningCenter = () => {
    const play = (categoryName = "", videoInfo, videoList) => {
      triggerModal({
        bool: true,
        props: {
          onPrev: showTrainningCenter,
          // enabledMeshes.SeoulTheater_Screen01.callback(
          //   categoryName,
          //   videoList
          // ),
          play,
          videoInfo,
          videoList,
        },
        content: {
          title: categoryName,
          Component: (props) => {
            return <VOD {...props} />;
          },
          hasOwnContainer: true,
        },
      });
    };
    triggerModal({
      bool: true,
      props: {
        categoryList: theaterCategoryList,
        play,
        close,
      },
      content: {
        title: "Training Center",
        Component: (props) => {
          return <TheaterVodList {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });
  };

  const logout = () => {
    _logout(loginType)
      .then((_) => {
        if (!window.location.pathname.includes("/exhibition/booth")) {
          showLoginModal(loginType, loginLogo);
        }
      })
      .catch((err) => console.log("logout error ::", err));
  };
  const showMyPageModal = () => {
    triggerModal({
      bool: true,
      props: {
        user,
        privacy_consent,
        user_consent,
        refetch,
      },
      content: {
        title: "MY PAGE",
        Component: (props) => {
          return (
            <MyInfo {...props}>
              <UserPolicy />
            </MyInfo>
          );
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });
  };

  const selectLanguage = (e) => {
    const lang = e.currentTarget.value || language;
    setLanguage(lang);
    refetchEventInfo(lang);
  };

  useEffect(() => {
    setLanguage(mainLang);
    if (navigationSet) {
      const {
        networking_modal_yn,
        membership_modal_yn,
        vod_modal_yn,
        replay_modal_yn,
      } = navigationSet;

      const commonNavList = [
        {
          icon: "calendar",
          onClick: showFuncCollection.showProgramInfo,
          name:
            commonNavMenu?.program[mainLang] || commonNavMenu?.program["en"],
        },
      ];

      // if (guide_yn === "Y") {
      // }
      // if (stamp_yn === "Y") {
      // }
      // if (channeltalk_yn === "Y") {
      // }
      if (replay_modal_yn === "Y") {
        commonNavList.push({
          icon: "videocam-outline",
          onClick: showFuncCollection.showReplay,
          name: commonNavMenu?.replay[mainLang] || commonNavMenu?.replay["en"],
        });
      }
      if (vod_modal_yn === "Y") {
        commonNavList.push({
          icon: "caret-forward-circle-outline",
          onClick: showTrainningCenter,
          name: commonNavMenu?.vod[mainLang] || commonNavMenu?.vod["en"],
        });
      }
      if (networking_modal_yn === "Y") {
        commonNavList.push({
          icon: "git-network-outline",
          onClick: showFuncCollection.showNetworking,
          name:
            commonNavMenu?.networking[mainLang] ||
            commonNavMenu?.networking["en"],
        });
      }

      if (membership_modal_yn === "Y") {
        commonNavList.push({
          icon: "people-outline",
          onClick: showFuncCollection.showMembership,
          name:
            commonNavMenu?.membership[mainLang] ||
            commonNavMenu?.membership["en"],
        });
      }

      setCommonNav(commonNavList);
    }
  }, [mainLang, navigationSet, socket]);

  return (
    <header className="header--2D">
      <div
        className={!isClicked ? "hamburger--2D" : "hamburger--2D active"}
        onClick={toggle}
      >
        <div className="hamburger__line--2D"></div>
        <div className="hamburger__line--2D"></div>
        <div className="hamburger__line--2D"></div>
      </div>

      {location.pathname.includes("booth") && (
        <div
          className={`header__booth-goback ${isClicked ? "active" : ""}`}
          onClick={() => {
            history.push(`/exhibition/indoor`);
          }}
        >
          <ion-icon
            class="header__booth-goback__icon"
            name="arrow-back-outline"
          ></ion-icon>
        </div>
      )}
      <div
        className={
          !isClicked ? "header__sidebar--2D" : "header__sidebar--2D active"
        }
      >
        <div className="header__logobox--2D">
          <a href="/" className="header__logo--2D logo">
            Event Logo
            <h1 className="header__logo--2D logo">
              <img
                alt="logo image"
                src={
                  navigationSet?.event_logo ||
                  `${PUBLIC_URL}/assets/logo/nav_logo.png`
                }
              />
            </h1>
          </a>
        </div>

        <div className="profile--2D">
          <section className="profile__top--2D clearfix">
            <strong className="username">{isLogin ? user.name : ""}</strong>
            <div className="select">
              <label htmlFor="language" className="select__language--label">
                {/* Select Language */}
              </label>
              <select
                name="language"
                id="language"
                className="select__language"
                onChange={selectLanguage}
                value={mainLang ? mainLang : ""}
              >
                {languages.length
                  ? languages.map((v) => (
                      <option key={v} value={v}>
                        {languageOptions[v]}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </section>
          <section className="profile__bottom--2D clearfix">
            {isLogin ? (
              <>
                <a className="mypage" onClick={showMyPageModal}>
                  My Page
                </a>
                <button className="login" onClick={logout}>
                  <ion-icon name="arrow-forward" className="icon"></ion-icon>
                  <span>Logout</span>
                </button>
              </>
            ) : loginType === "unlogin" ? null : (
              <button
                className="login"
                onClick={() => {
                  showLoginModal(loginType, loginLogo);
                }}
              >
                <ion-icon name="arrow-forward" className="icon"></ion-icon>
                <span>Login</span>
              </button>
            )}
          </section>
        </div>
        <nav className="nav--2D">
          <ul className="nav__wrap--2D">
            {navNames.length &&
              navNames.map((k, i) => {
                if (k === "__typename") return;
                return (
                  <NavItem key={i} path={k} name={navigationSet.names[k]} />
                );
              })}
          </ul>
          <ul className="nav__wrap--bottom--2D">
            <CommonNav2D
              navs={commonNav}
              setIsClicked={setIsClicked}
              triggerModal={triggerModal}
            />
          </ul>

          <ul className="nav__wrap--2D">
            <li className="nav__item--2D">
              <a href="https://www.salin.co.kr/" target="_blank">
                <p className="license">
                  Copyright © 2021.
                  <br />
                  Provided by Salin
                  <br />
                  All rights reserved.
                </p>
              </a>
            </li>
          </ul>
        </nav>
        {/* </div> */}
        {/* <div className="license">© 2021. Salin Inc. All rights reserved.</div> */}
      </div>
    </header>
  );
};

Navbar.fragments = {
  navigationSet: gql`
  fragment NavbarFragment on Event {
    ${navSet}
  }
`,
};

export default Navbar;
