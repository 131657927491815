import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import AppContainer from "./AppContainer";
import GlobalStyle from "./style/GlobalStyle";

const root = document.getElementById("root");
if (root) {
  ReactDOM.render(
    <Router>
      <GlobalStyle />
      <AppContainer />
    </Router>,
    root
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
