import React, { useState, useEffect, useRef } from "react";
import * as html2canvas from "html2canvas";
import moment from "moment";
import Intro from "./membership/Intro";
import PreviewPicture from "./membership/PreviewPicture";
import WebCamContainer from "./membership/WebCamContainer";
import NameTranslation from "./membership/NameTranslation";
import FrontCardBox from "./membership/FrontCardBox";
import { useProgressiveImage } from "../../hooks/useProgressiveImage";
import { gql, useQuery } from "@apollo/client";

const MEMBERSHIP = gql`
  query ($language: LANGUAGE_TYPE) {
    membershipSet(language: $language) {
      idx
      event_idx
      card_top_url
      card_bottom_url
    }
  }
`;

const MemberShip = ({ openAlert, close }) => {
  const [page, setPage] = useState(1);
  const [photo, setPhoto] = useState(null);

  const { loading, error, data } = useQuery(MEMBERSHIP);
  const membershipSet = data?.membershipSet;

  const name = useRef({
    kr: "",
    en: "",
  });
  const local = moment.utc().local().format("YYYY-MM-DD");
  const prev = (v) =>
    setPage((typeof v === "number" && v) || ((prev) => prev - 1));
  const next = (v) =>
    setPage((typeof v === "number" && v) || ((prev) => prev + 1));

  const cardBg = useProgressiveImage();

  return (
    <div className="modal">
      <FrontCardBox name={name} local={local} photo={photo} cardBg={cardBg} />
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          {page > 1 ? (
            <button className="back" onClick={page >= 3 ? () => prev(2) : prev}>
              <ion-icon name="arrow-back"></ion-icon>
            </button>
          ) : null}
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>Create your Membership</h2>
            <p>
              Create a membership card by entering your name and taking
              pictures.
            </p>
          </section>
        </div>

        {page === 1 && <Intro membershipSet={membershipSet} next={next} />}

        {page === 2 && (
          <PreviewPicture
            membershipSet={membershipSet}
            prev={prev}
            next={next}
            name={name.current}
            photo={photo}
            openAlert={openAlert}
          />
        )}
        {page === 3 && (
          <WebCamContainer
            prev={prev}
            setPhoto={setPhoto}
            openAlert={openAlert}
          />
        )}

        {page === 4 && (
          <NameTranslation
            name={name.current}
            prev={prev}
            openAlert={openAlert}
          />
        )}
      </div>
    </div>
  );
};

export default MemberShip;
