import React from "react";
import { PUBLIC_URL } from "../../../config/define";

const Alert = ({ alert, onClose }) => {
  return (
    <div className="modal" style={{ zIndex: 10000 }}>
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="modal__header">
          {onClose && (
            <button className="close" onClick={onClose}>
              <ion-icon name="close"></ion-icon>
            </button>
          )}

          <section className="modal__tit">
            {alert.type === "success" ? (
              <>
                <img
                  className="success"
                  src={`${PUBLIC_URL}/assets/img/icon_success.png`}
                  alt="success"
                />
                <h2>Success</h2>
              </>
            ) : (
              <>
                <img
                  className="alert"
                  src={`${PUBLIC_URL}/assets/img/icon_alert.png`}
                  alt="alert"
                />
                <h2>Alert</h2>
              </>
            )}
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            <pre className="alert-msg">{alert.msg}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
