import React, { useState } from "react";
import { PUBLIC_URL } from "../../../../../config/define";

const SpeakerDetail = ({ profile, close }) => {
  const { name, job_title, biodata, photo_url, organization, country } =
    profile;
  const [selectedTab, setSelectedTab] = useState(1);
  const select = (e) => setSelectedTab(+e.currentTarget.dataset.tab || 1);

  return (
    <div className="modal popup moderator" id="moderator">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small mb-top zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h3>Speaker Information</h3>
          </section>
        </div>
        <div className="modal__content">
          <div className="content-box">
            <ul className="moderator__tabNav clearfix">
              <li className="moderator__tabNav--item">
                <button
                  className={
                    selectedTab === 1
                      ? "moderator__tabNav--title active"
                      : "moderator__tabNav--title"
                  }
                  data-tab="1"
                  onClick={select}
                >
                  CV
                </button>
              </li>
              <li className="moderator__tabNav--item">
                <button
                  className={
                    selectedTab === 2
                      ? "moderator__tabNav--title active"
                      : "moderator__tabNav--title"
                  }
                  data-tab="2"
                  onClick={select}
                >
                  {profile.hasOwnProperty("abstract") ? "Abstract" : "Bio Data"}
                </button>
              </li>
            </ul>
            <div>
              <div
                className={
                  selectedTab === 1
                    ? "moderator__tabCont target"
                    : "moderator__tabCont"
                }
                id="tab1"
              >
                <div className="moderator__img">
                  <img
                    src={
                      photo_url
                        ? photo_url
                        : `${PUBLIC_URL}/assets/img/speaker_info.png`
                    }
                    alt="Moderator Image"
                  />
                </div>
                <div className="moderator__details">
                  <p className="moderator__tit">Name</p>
                  <p className="moderator__name">{name}</p>
                  <p className="moderator__tit">Organization</p>
                  <p className="moderator__country">{organization}</p>
                  <p className="moderator__tit">Job Title</p>
                  <p className="moderator__position">{job_title}</p>
                  <p className="moderator__tit">Country</p>
                  <p className="moderator__country">{country}</p>
                </div>
              </div>
              <div
                className={
                  selectedTab === 2
                    ? "moderator__tabCont target"
                    : "moderator__tabCont"
                }
                id="tab2"
              >
                <div className="explan_txt">
                  <pre>
                    {profile.hasOwnProperty("abstract")
                      ? profile.abstract
                      : biodata}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerDetail;
