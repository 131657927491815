import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {

  }


  button:disabled {
    cursor: default;
  }
`;

export default GlobalStyle;
