import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import define from "../../config/define";
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import WebGLUtil from "../../util/webgl";
import { ModalPortalContext } from "../../context/ModalContext";
import Brochure from "../../components/tower/Brochure";
import PromoImage from "../../components/exhibition/PromoImage";
import PromoVideo from "../../components/exhibition/PromoVideo";
import ExhibitorList from "../../components/exhibition/type/ExhibitorList";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import { companyBoothSet } from "../../query/queries";
import { BoothContext } from "../../context/BoothContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import { useHistory } from "react-router";
import CompanyInfo from "../../components/exhibition/CompanyInfo";
import util from "../../util";
import PSAMeetingImage from "../../components/psa/PSAMeetingImage";
import BizCard from "../../components/exhibition/BizCard";
import usePrevious from "../../hooks/usePrevious";
import useResize from "../../hooks/useResize";
import styled from "styled-components";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import CounselorList from "../../components/common/modal/contents/CounselorList";
import { Link } from "react-router-dom";
import PulsingAnimation from "../../components/common/pulsingAnimation/PulsingAnimation";

const BoothTitle = styled.div`
  position: absolute;
  top: 198px;
  left: 880px;
  width: 680px;
  height: 100px;
  // background-color: rgba(255, 0, 0, 0.5);
  cursor: pointer;
  z-index: 2;
  color: transparent;
  display: flex;
  justify-content: center;
  font-size: 3rem;

  .booth_title {
    position: relative;
    text-align: center;
    font-size: 250%;
    font-weight: bold;
    top: 10px;
    left: -3px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    // display: inline-block;
    width: 100%;
  }
`;
const BoothBanner = styled.div`
  position: absolute;
  top: 432px;
  left: 148px;
  width: 155px;
  height: 260px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: default;
  // z-index: 2;

  .pulsing_animation {
    position: relative;
    left: 100%;
    top: -50%;
  }

  .booth_banner {
    position: relative;
    img {
      position: relative;
      width: 155px;
      height: 260px;
      // transform: scale(0.);
    }
  }
`;
const SendEmailBtn = styled.a`
  position: absolute;
  top: 780px;
  left: 760px;
  width: 97px;
  height: 170px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 45px;
    top: 6px;
  }
`;
const BrochureOpener = styled.div`
  position: absolute;
  top: 675px;
  left: 1490px;
  width: 100px;
  height: 300px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;

  .pulsing_animation {
    position: relative;
    left: 45px;
    top: -275px;
  }

  .brochure_top_cover_image {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 7px;
    top: 2px;
    width: 85px;
    height: 120px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }

  .brochure_bottom_cover_image {
    // background-color: rgba(255, 0, 0, 0.5);
    position: relative;
    left: 8px;
    top: 9px;
    width: 85px;
    height: 135px;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const LogoUrl = styled.div`
  position: absolute;
  top: 78px;
  left: 105px;
  width: 490px;
  height: 150px;
  font-size: 25px;
  //background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  // color: transparent;

  .pulsing_animation {
    position: relative;
    left: 50%;
    top: -5%;
  }

  .logo_url {
    position: relative;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;
const VideoUrl = styled.div`
  position: absolute;
  top: 427px;
  left: 1120px;
  width: 260px;
  height: 150px;
  // background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
`;

const InfoOpener = styled.div`
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 530px;
  left: 695px;
  width: 80px;
  height: 60px;
  cursor: pointer;
  img {
    object-fit: fill;
    width: 100%;
    heihgt: 100%;
  }
  .pulsing_animation {
    position: relative;
    // top: -50%;
    // left: -5%;
  }
`;

export const COMPANY_BOOTH_SET = gql`
  ${companyBoothSet}
`;

const VIDEO_COUNSELOR_LIST = gql`
  query VIDEO_COUNSELOR_LIST($company_idx: Int) {
    counselorList(company_idx: $company_idx) {
      idx
      company_idx
      name
      meeting_url
      active_yn
    }
  }
`;

const BoothStyle1 = ({
  loginType,
  triggerModal,
  setStamp,
  companyBoothSet,
  contentWrapStyle,
  contentResizeStyle,
}) => {
  const {
    booth_banner,
    booth_title,
    company_idx: id,
    logo_url,
    video_url1,
    companyBrochureList: brochureList,
    meeting_cover_image,
    brochure_top_cover_image,
    brochure_bottom_cover_image,
    company,
  } = companyBoothSet;
  // console.log("companyBoothSet>>", companyBoothSet);
  const sceneRef = useRef(null);
  const meetingListRef = useRef(null);
  const history = useHistory();
  const { user, isLogin } = useContext(UserLoginContext);
  const { boothList, categoryList, refetch } = useContext(BoothContext);
  const [companyBoothInfo, setCompanyBoothInfo] = useState({});
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [isCompanyBoothInfoApplied, setIsCompanyBoothInfoApplied] =
    useState(false);
  const [onLazyLoading, setOnLazyLoading] = useState(true);
  const advancedTextureRef = useRef(null);
  const prevIsLogin = usePrevious(isLogin);
  const [selectedCounselorIdx, setSelectedCounselorIdx] = useState();
  const { close } = useContext(ModalPortalContext);

  const [
    getCounselorList,
    {
      loading: isGetCounselorListLoading,
      error: getCounselorListError,
      data: counselors,
      called: calledCounselors,
      refetch: refetchCounselorList,
    },
  ] = useLazyQuery(VIDEO_COUNSELOR_LIST, {
    variables: {
      company_idx: +id,
    },
    notifyOnNetworkStatusChange: true,
  });

  // const goToExhibitionHall = () => {
  //   history.push("/exhibition/indoor");
  // };
  // const copyBoothUrl = () => navigator.clipboard.writeText(location.href);

  // useEffect(() => {
  //   if (id && isSceneReady && !calledCounselors) {
  //     getCounselorList({ variables: { company_idx: +id } });
  //   }

  //   if (calledCounselors && isLogin !== prevIsLogin) {
  //     refetchCounselorList({ company_idx: +id });
  //   }
  // }, [id, isSceneReady, isLogin, calledCounselors]);

  const bizMeetingOpen = () => {
    if (!isGetCounselorListLoading && !calledCounselors) {
      getCounselorList();
      // getCounselorList({ variables: { company_idx: +id } });
    } else if (!isGetCounselorListLoading && calledCounselors) {
      refetchCounselorList();
      // refetchCounselorList({ company_idx: +id });
    } else if (getCounselorListError) {
      console.log("getCounselorListError ::", getCounselorListError);
    }
  };

  useEffect(() => {
    // console.log("counselors", counselors);
    if (!isGetCounselorListLoading && calledCounselors) {
      if (counselors) {
        if (counselors.counselorList) {
          triggerModal({
            bool: true,
            props: {
              counselors,
              selectCounselor,
              // selectedCounselorIdx,
              close,
            },
            content: {
              title: "",
              Component: (props) => {
                return <CounselorList {...props} />;
              },
              hasOwnContainer: true,
            },
          });
        }
      }
    }
  }, [counselors, isGetCounselorListLoading, calledCounselors]);

  const selectCounselor = (e) => {
    const { id: _selectedCounselorIdx } = e.target.dataset;
    // console.log("_selectedCounselorIdx", _selectedCounselorIdx);
    setSelectedCounselorIdx(_selectedCounselorIdx);
  };

  useEffect(() => {
    // console.log("selectedCounselorIdx", selectedCounselorIdx);
    if (!isGetCounselorListLoading && calledCounselors) {
      if (counselors) {
        if (counselors.counselorList) {
          if (Object.keys(counselors.counselorList).length) {
            const target = counselors?.counselorList?.find(
              (el) => el.active_yn == "Y" && el.idx == selectedCounselorIdx
            );
            if (target) {
              window.open(
                counselors?.counselorList?.find(
                  (el) => el.active_yn == "Y" && el.idx == selectedCounselorIdx
                )?.meeting_url,
                "_blank"
              );
            } else {
              alert("지금은 상담이 불가능한 방입니다.");
            }
          }
        }
      }
    }
  }, [selectedCounselorIdx]);

  const videoOpener = async () =>
    triggerModal({
      bool: true,
      props: {
        selectedVod: {
          title: "SCREEN VIDEO",
          url: video_url1,
        },
      },
      content: {
        title: "",
        Component: (props) => {
          return <VideoOnly {...props} />;
        },
        hasOwnContainer: true,
      },
    });

  const brochureOpen = () =>
    triggerModal({
      bool: true,
      props: { brochureList },
      content: {
        title: "",
        Component: (props) => {
          return <Brochure {...props} />;
        },
        hasOwnContainer: true,
      },
    });

  const openCompanyInfo = (c, { hasPrevButton }) => {
    triggerModal({
      bool: true,
      props: {
        companyInfo: c,
        loginType,
        // onPrev: openExhibitorList,
      },
      content: {
        title: "",
        Component: (props) => {
          return <CompanyInfo {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton,
    });
  };

  return (
    <>
      <img
        alt="exhibition BG image"
        src={define.RESOURCE.Booth.Style1}
        className="lobby__background"
      />
      <BoothTitle>
        <div className="booth_title">
          {/* <p>일이삼사오육칠팔구십</p> */}
          <p>{booth_title}</p>
        </div>
      </BoothTitle>
      <InfoOpener
        onClick={() => openCompanyInfo(company, { hasPrevButton: false })}
      >
        <img src={define.RESOURCE.Booth.Icon.info} alt="company info" />
        <PulsingAnimation />
      </InfoOpener>
      <BoothBanner>
        <span className="booth_banner">
          <img src={booth_banner} />
        </span>
        {/* <PulsingAnimation /> */}
      </BoothBanner>
      <SendEmailBtn
        onClick={() => {
          console.log(`mailto: ${company.email}`);
          window.location = `mailto:${company.email}`;
        }}
      >
        <PulsingAnimation />
      </SendEmailBtn>
      <BrochureOpener onClick={brochureOpen}>
        <div className="brochure_top_cover_image">
          <img alt="brochure_top_cover_image" src={brochure_top_cover_image} />
        </div>
        <div className="brochure_bottom_cover_image">
          <img
            alt="brochure_bottom_cover_image"
            src={brochure_bottom_cover_image}
          />
        </div>
        <PulsingAnimation />
      </BrochureOpener>
      <LogoUrl>
        <span className="logo_url">
          <a href={company.website_url} target="_blank">
            <img src={logo_url} />
          </a>
        </span>
        <PulsingAnimation />
      </LogoUrl>
      <VideoUrl>
        <iframe
          src={video_url1}
          // src="https://player.vimeo.com/video/631527659?controls=0&autoplay=1&loop=1&autopause=0&muted=1"
          style={{ width: "100%", height: "100%" }}
          allow="camera; microphone; autoplay; fullscreen"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          // sandbox="true"
        />
        <div
          style={{
            position: "relative",
            top: "-265px",
            height: "270px",
            zIndex: "2",
          }}
          onClick={videoOpener}
        />
      </VideoUrl>
    </>
  );
};

export default BoothStyle1;
