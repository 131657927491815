import React from "react";

const GuideScreen = () => {
  const [openGuideScreen, setOpenGuideScreen] = React.useState(
    window?.matchMedia("(orientation: portrait)")?.matches
  );
  React.useEffect(() => {
    window.onresize = function (event) {
      if (window.matchMedia("(orientation: landscape)").matches) {
        setOpenGuideScreen(false);
      }
    };
  }, []);
  if (!openGuideScreen) return <></>;
  return (
    <div class="guide-screen">
      <div class="guide-screen__wrapper">
        <img
          src="/assets/img/icon_landscape.png"
          alt="Screen rotation"
          class="guide-screen__img"
        />
        <p class="guide-screen__txt">Please use landscape mode.</p>
      </div>
    </div>
  );
};

export default GuideScreen;
