import { gql, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { BoothContext } from "../../context/BoothContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import usePrevious from "../../hooks/usePrevious";
import useResize from "../../hooks/useResize";
import { companyBoothSet2D } from "../../query/queries";
import Booth2DStyle1 from "./BoothStyle1";
import Booth2DStyle2 from "./BoothStyle2";

export const COMPANY_BOOTH_SET = gql`
  ${companyBoothSet2D}
`;

const VIDEO_COUNSELOR_LIST = gql`
  query VIDEO_COUNSELOR_LIST($company_idx: Int) {
    counselorList(company_idx: $company_idx) {
      idx
      company_idx
      name
      meeting_url
      active_yn
    }
  }
`;

const Booth = ({ id, loginType, triggerModal, setStamp }) => {
  const sceneRef = useRef(null);
  const meetingListRef = useRef(null);
  const history = useHistory();
  const { user, isLogin } = useContext(UserLoginContext);
  const { boothList, categoryList, refetch } = useContext(BoothContext);
  const [companyBoothInfo, setCompanyBoothInfo] = useState({});
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [isCompanyBoothInfoApplied, setIsCompanyBoothInfoApplied] =
    useState(false);
  const [onLazyLoading, setOnLazyLoading] = useState(true);
  const advancedTextureRef = useRef(null);
  const prevIsLogin = usePrevious(isLogin);
  const { error, loading, data } = useQuery(COMPANY_BOOTH_SET, {
    variables: {
      company_idx: +id,
    },
  });

  const companyBoothSet = data?.companyBoothSet;

  const [
    getCounselorList,
    {
      loading: isGetCounselorListLoading,
      error: getCounselorListError,
      data: counselors,
      called,
      refetch: refetchCounselorList,
    },
  ] = useLazyQuery(VIDEO_COUNSELOR_LIST);
  const contentWrapRef = useRef(null);
  const { contentWrapStyle, contentResizeStyle } = useResize(contentWrapRef);

  useEffect(() => {
    if (id && isSceneReady && !called) {
      getCounselorList({ variables: { company_idx: +id } });
    }

    if (called && isLogin !== prevIsLogin) {
      refetchCounselorList({ company_idx: +id });
    }
  }, [id, isSceneReady, isLogin, called]);

  useEffect(() => {
    if (error) {
      console.log("query companyBoothSet error ::", error);
    }

    if (getCounselorListError) {
      console.log("query VIDEO_COUNSELOR_LIST error ::", getCounselorListError);
    }
  }, [error, getCounselorListError]);

  useEffect(() => {
    if (isCompanyBoothInfoApplied) {
      setOnLazyLoading(false);
    }

    return () => {
      if (isCompanyBoothInfoApplied) {
        setIsCompanyBoothInfoApplied(false);
        setIsSceneReady(false);
        setOnLazyLoading(true);
      }
    };
  }, [isCompanyBoothInfoApplied]);

  return (
    <>
      <div
        style={{
          height: "100%",
          background:
            "linear-gradient(rgb(4, 16, 21) 0%, rgb(12, 41, 54) 100%)",
        }}
      >
        <div
          className="lobby wrapper login-wrap"
          style={{
            height: "100%",
            position: "relative",
            background: "none",
          }}
        >
          <div className="content-wrap" style={contentWrapStyle}>
            <div
              className="canvas-wrap"
              ref={contentWrapRef}
              style={contentResizeStyle}
            >
              <div
                style={{
                  width: "322px",
                  height: "104px",
                  position: "absolute",
                  top: "404px",
                  display: "block",
                  cursor: "pointer",
                  zIndex: "2",
                  color: "transparent",
                }}
              ></div>
              {companyBoothSet?.class_type === "silver" && (
                <Booth2DStyle1
                  loginType={loginType}
                  triggerModal={triggerModal}
                  setStamp={setStamp}
                  companyBoothSet={companyBoothSet}
                />
              )}
              {companyBoothSet?.class_type === "gold" && (
                <Booth2DStyle2
                  loginType={loginType}
                  triggerModal={triggerModal}
                  setStamp={setStamp}
                  currentBoothData={companyBoothSet}
                />
              )}
              {/* {companyBoothInfo.class_type === 'platinum' && <Booth2DStyle3 />} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booth;
