import React, { useState, useContext, useEffect } from "react";
import { from, gql, useMutation } from "@apollo/client";
import { ModalPortalContext } from "../../../../context/ModalContext";
import util from "../../../../util/index";
import FindPW from "./FindPW";
import AccountRegistration from "./accountRegistration/AccountRegistration";
import UserPolicy from "./registration/UserPolicy";
import { PUBLIC_URL } from "../../../../config/define";
import { UserLoginContext } from "../../../../context/UserLoginContext";
import { addStatisticVisit } from "../../../../query/queries";
import { EventContext } from "../../../../context/EventContext";

const ADD_STATISTIC_VISIT = gql`
  ${addStatisticVisit}
`;

const USER_LOGIN = gql`
  mutation loginUser($input: loginInput) {
    loginUser(input: $input) {
      name
      email
      job_title
      organization
      country
      role
      token
    }
  }
`;

const Login = ({
  loginType,
  close,
  setUser,
  getMyInfo,
  setIsLogin,
  loginsetData,
  loginLogo,
}) => {
  const [addStatisticVisit] = useMutation(ADD_STATISTIC_VISIT);
  const { triggerModal, openAlert } = useContext(ModalPortalContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [login, { data, loading, error }] = useMutation(USER_LOGIN, {
    onError: (err) => {
      // setIsLoading(false);
    },
  });

  const event = React.useContext(EventContext);

  const {
    custom_input_name,
    custom_input_yn,
    mobile_no_yn,
    name_yn,
    organization_yn,
  } = loginsetData?.event?.loginSet;

  const onChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!(form.email && form.password)) {
      openAlert({
        bool: true,
        msg: `Please check your ID and password
아이디와 비밀번호를 확인해주세요
`,
      });
      setValidated(true);
      return false;
    }
    try {
      login({ variables: { input: form } });
    } catch (err) {
      console.log("login request network error ::", err);
    }
  };

  const showFindPw = () => {
    triggerModal({
      bool: true,
      props: {
        onPrev: () =>
          triggerModal({
            bool: true,
            props: {
              // close,
              getMyInfo,
              setUser,
              loginType,
              setIsLogin,
              loginsetData,
              loginLogo,
            },
            content: {
              title: "LOGIN",
              Component: (props) => <Login {...props} />,
              hasOwnContainer: true,
            },
            hasPrevButton: false,
          }),
      },
      content: {
        title: "find password",
        Component: (props) => {
          return <FindPW {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });
  };

  const showRegistration = () => {
    triggerModal({
      bool: true,
      props: {
        onPrev: () =>
          triggerModal({
            bool: true,
            props: {
              // close,
              getMyInfo,
              setUser,
              loginType,
              setIsLogin,
              loginsetData,
              loginLogo,
            },
            content: {
              title: "LOGIN",
              Component: (props) => <Login {...props} />,
              hasOwnContainer: true,
            },
            hasPrevButton: false,
          }),
        openAlert,
      },
      content: {
        title: "Registration",
        Component: (props) => {
          return <AccountRegistration {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: true,
    });
  };

  const onRememberChanged = () => {
    setRememberMe((prev) => !prev);
  };

  useEffect(() => {
    if (!loading && data) {
      const { loginUser } = data;
      const { token } = loginUser;
      document.cookie = `clientToken=${token}; path=/; ${
        rememberMe ? "max-age=360000" : ""
      };`;
      // setUser(loginUser);
      setIsLogin(true);
      addStatisticVisit();
      close();
    }
  }, [loading, data]);

  useEffect(() => {
    if (error) {
      openAlert({ bool: true, msg: error.message });
    }
  }, [error]);

  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="modal__header">
          {/* <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button> */}
          <section className="modal__tit">
            <h2 className="ir_so">Login</h2>
            <div className="modal__tit--logo">
              <img
                src={
                  loginLogo
                    ? loginLogo
                    : `${PUBLIC_URL}/assets/logo/govent_logo_org.png`
                }
                alt="Logo Image"
              />
            </div>
          </section>
        </div>
        <div className="modal__content lgmh">
          <div className="loginbox">
            <form
              className={
                validated ? "loginbox__form was-validated" : "loginbox__form"
              }
            >
              <div className="text__box">
                <label htmlFor="email">
                  <p>Email(ID)</p>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Enter your email(ID)."
                    onChange={onChange}
                  />
                </label>
              </div>
              <div className="text__box">
                <label htmlFor="password">
                  <p>Password</p>
                  <input
                    name="password"
                    type="password"
                    required
                    placeholder="Enter your password."
                    onChange={onChange}
                  />
                </label>
              </div>
              {organization_yn === "Y" && (
                <div className="text__box">
                  <label htmlFor="organization">
                    <p>Organization</p>
                    <input
                      type="organization"
                      name="organization"
                      required
                      placeholder="Enter your organization"
                      onChange={onChange}
                    />
                  </label>
                </div>
              )}
              {name_yn === "Y" && (
                <div className="text__box">
                  <label htmlFor="name">
                    <p>Name</p>
                    <input
                      type="name"
                      name="name"
                      required
                      placeholder="Enter your name"
                      onChange={onChange}
                    />
                  </label>
                </div>
              )}
              {mobile_no_yn === "Y" && (
                <div className="text__box">
                  <label htmlFor="mobile">
                    <p>Mobile</p>
                    <input
                      type="mobile"
                      name="mobile"
                      required
                      placeholder="Enter your mobile"
                      onChange={onChange}
                    />
                  </label>
                </div>
              )}
              {custom_input_yn === "Y" && (
                <div className="text__box">
                  <label htmlFor={custom_input_name}>
                    <p>{custom_input_name}</p>
                    <input
                      type={custom_input_name}
                      name={custom_input_name}
                      required
                      placeholder={`Enter your ${custom_input_name}`}
                      onChange={onChange}
                    />
                  </label>
                </div>
              )}
              <div className="loginbox__ipt">
                <span className="remember">
                  <label htmlFor="remember" className="remember__pw">
                    <input
                      type="checkbox"
                      name="remember"
                      id="remember"
                      checked={rememberMe}
                      onChange={onRememberChanged}
                    />
                    Remember me
                  </label>
                </span>
                <span className="reset">
                  <button
                    type="button"
                    className="reset__pw"
                    onClick={showFindPw}
                  >
                    Find Password
                  </button>
                </span>
              </div>
              <div className="loginbox__button">
                {!loginType ||
                  (loginType === "join" && (
                    <button
                      type="button"
                      className="registration"
                      onClick={showRegistration}
                    >
                      Register
                    </button>
                  ))}
                <button type="submit" className="login" onClick={handleLogin}>
                  Login
                </button>
              </div>
              <p className="error-msg hidden">
                Check your email(ID) and password
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
