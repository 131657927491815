const user = {
  header: "Terms of Use",
  title: "Virtual Seoul Terms of Use",
  content: (
    <p>
      <b>Article 1 (Purpose)</b>
      <br />
      The purpose of these Terms and Conditions is to specify the user’s rights
      and obligations in using the Internet-related services (hereinafter, “the
      service”) provided by the Virtual Seoul website (hereinafter, “website”)
      operated by the Seoul Tourism Organization.
      <br />
      <br />
      <b>Article 2 (Definition)</b>
      <br />
      <small>&#10112;</small> “Website” means the website which the Organization
      provides to the “users” for the purposes of participating in online
      events.
      <br />
      <small>&#10113;</small> “User” means a member who accesses the “website”
      and receives the services provided by the “website” in accordance with
      these Terms and Conditions.
      <br />
      <small>&#10114;</small> “Member” means an individual who register for
      membership and uses the services provided by the “website.”
      <br />
      <br />
      <b>
        Article 3 (Articulation and Explanation and Amendment of the Terms and
        Conditions)
      </b>
      <br />
      <small>&#10112;</small> The “website” shall be posted in the initial
      service screen of the “website” to ensure that the users can easily
      identify the contents of these Terms and Conditions, tradename and the
      name of the representative, address of the business office (including the
      address where the consumers can process their complaints), telephone
      number, e-mail address, business registration number, and privacy manager.
      However, the contents of these Terms and Conditions can be made available
      to the users via the connection screen.
      <br />
      <small>&#10113;</small> The “website” shall obtain a confirmation from the
      users by providing a separate connection screen or a pop-up screen so that
      the users can understand important contents of the contents defined before
      the users agree to the Terms and Conditions.
      <br />
      <small>&#10114;</small> The “website” may amend these Terms and Conditions
      to the extent that they do not violate the relevant laws such as the “Act
      on the Regulation of Terms and Conditions” and the “Act on the Promotion
      of Information Network Use and Information Protection, Etc.”
      <br />
      <small>&#10115;</small> In case of amending the Terms and Conditions on
      the “website”, the date of the amendment’s application and the reasons for
      the amendment shall be specified and posted on the initial screen of the
      “website” together with the current Terms and Conditions from 15 days
      before the effective date until the day before the effective date.
      However, if the contents of the agreement are changed unfavorably against
      the users, the changes to the Terms and Conditions shall be notified to
      the user with at least 30 days’ advance grace period. In this event, the
      “website” shall clearly compare the contents before and after the
      amendment, and display them as for the users to easily understand.
      <br />
      <small>&#10116;</small> In the case of amending the Terms and Conditions
      on the “website,” the amended Terms and Conditions shall apply only to the
      contracts executed after the effective date of the application of the
      amendment, and the previously executed contracts shall be subjected to the
      provisions of the Terms and Conditions before the amendment. However, if
      the users who already executed the contracts transmit the intention to be
      subjected to the provisions of the amendment to the “website” within the
      notice period of the amendment pursuant to Paragraph 3 and obtain consent
      of the “website,” they shall be subjected to the provisions of the amended
      Terms and Conditions.
      <br />
      <br />
      <b>Article 4 (Provision and Change of the Service)</b>
      <br />
      <small>&#10112;</small> The “website” performs the following businesses.
      <br />
      &emsp;a. Provide e-conference functions (video contents sent out in the
      form of VOD, live streaming, webinar)
      <br />
      &emsp;b. Networking Service between delegates (1:1 meeting, group chat,
      business information exchange, etc.)
      <br />
      &emsp;c. 1:1 Business meeting (PSA matching system)
      <br />
      &emsp;d. Virtual exhibition functions (showcasing exhibitors’ promotional
      contents)
      <br />
      &emsp;e. Other “website” businesses
      <br />
      <br />
      <b>Article 5 (Term and Termination of Service)</b>
      <br />
      <small>&#10112;</small> The service period of the users is from the day
      they visit the “website” until the end of the service use period.
      <br />
      <small>&#10113;</small> The “website” may temporarily suspend the service
      for any reason such as maintenance, inspection, replacement, failure of
      the information and communications equipment such as computer, failure of
      communication, etc., in which event the facts of the suspension of the
      service and reasons shall be notified on the initial page.
      <br />
      <small>&#10114;</small> The “website” may limit or suspend the provision
      of the service if it cannot provide the service due to natural disasters
      or force majeure as such.
      <br />
      <br />
      <b>Article 6 (Privacy Protection)</b>
      <br />
      <small>&#10112;</small> The “website” collects minimum personal
      information necessary to provide the service when collecting the users’
      personal information.
      <br />
      <small>&#10113;</small> When collecting and using personal information of
      the users, the “website” notifies the users of the purpose and obtain
      their consent.
      <br />
      <small>&#10114;</small> The “website” cannot use the collected personal
      information for any purposes other than the stated purposes, and when a
      new purpose of use occurs or it is provided to a third party, the users
      are notified of the purpose and their consents are obtained in the use and
      provision phases. However, exceptions apply in the cases where there are
      other stipulations of the relevant laws and regulations.
      <br />
      <small>&#10115;</small> The “website” shall specify or notify in advance
      matters provided under Article 22 Paragraph 2 of the “Act on the Promotion
      of Information Network Use and Information Protection, Etc.” such as the
      identity (affiliation, name, telephone number, and other contact
      information) of the person in charge of the personal information
      management, purpose of collecting and using the information, and matters
      concerning the provision of information on the third party, and the users
      can withdraw their consent at any time.
      <br />
      <small>&#10116;</small> The “website” or any third party that receives
      personal information from it shall destroy the personal information
      without any delay when it has achieved the purpose of collecting or
      receiving the personal information.
      <br />
      <br />
      <b>Article 7 (Obligations of the “Website”)</b>
      <br />
      <small>&#10112;</small> The “website” shall not commit any acts prohibited
      by laws and regulations or contrary to public order, and shall endeavor to
      the extent possible to provide contents and experiential products
      continuously and stably as stipulated by these Terms and Conditions.
      <br />
      <small>&#10113;</small> The “website” shall have a security system to
      protect the users’ personal information (including credit information) so
      that they can use the Internet service safely.
      <br />
      <small>&#10114;</small> The “website” shall be liable to compensate the
      users for any damages caused by any unlawful display and advertisements
      provided under Article 3 of the “Act on the Fairness of Display and
      Advertisement” concerning the contents and experiential products.
      <br />
      <small>&#10115;</small> The “website” does not send commercial emails for
      for-profit purposes which the users do not want.
      <br />
      <br />
      <b>
        Article 8 (User’s Obligations) The users shall not commit the following
        acts.
      </b>
      <br />
      <small>&#10112;</small> Entering false information when registering or
      making changes
      <br />
      <small>&#10113;</small> Stealing of others’ information
      <br />
      <small>&#10114;</small> Changing the information posted on the “website”
      <br />
      <small>&#10115;</small> Transmission or posting of information (computer
      programs, etc.) other than the information determined by the “website”
      <br />
      <small>&#10116;</small> Infringement of intellectual property rights
      including the copyrights of the “website” and other third parties
      <br />
      <small>&#10117;</small> Any other acts that may damage or disrupt the
      reputation or business of the “website” and any third party
      <br />
      <small>&#10118;</small> Disclosure or posting to the “website” of any
      information such as obscenity or violent messages, images, voices, or
      others that may be against good public order and customs.
      <br />
      <br />
      <b>Article 9 (Reversion of Copyrights and Restrictions on Use)</b>
      <br />
      <small>&#10112;</small> The copyrights and other intellectual property
      rights for the works and products created by the “website” shall revert to
      the “website.”
      <br />
      <small>&#10113;</small> The users shall not use information reverting to
      the “website” obtained by using the “website” to for any for-profit
      purposes or allow or enable any third party to use the same without prior
      consent of the “website” by means such as copying, transmitting,
      publishing, distributing, broadcasting and other methods.
      <br />
      <small>&#10114;</small> The “website” shall notify users when using
      copyrighted material reverted to the users in accordance with the Terms
      and Conditions of Use.
      <br />
      <br />
      <b>Article 10 (Dispute Resolution)</b>
      <br />
      <small>&#10112;</small> The “website” shall install and operate a damage
      compensation processing organization to reflect legitimate opinions and
      complaints filed by the users and compensate for the damages incurred.
      <br />
      <small>&#10113;</small> The “website” shall prioritize handling matters
      pertinent to the complaints and opinions submitted by the users. However,
      if prompt handling is not feasible, it shall promptly notify the users of
      the reasons and the handling schedule immediately.
      <br />
      <br />
      <b>Article 11 (Jurisdiction and Governing Law)</b>
      <br />
      <small>&#10112;</small> Any dispute that arises between the “website” and
      the users shall be subject to the exclusive jurisdiction of the district
      court having jurisdiction over the address of the users at the time of
      filing a suit, and in the absence of an address, over the residence of the
      users. However, if the address or residence of the users at the time of
      filing a suit is not clear, or for the residents of foreign countries,
      they shall submit their case to the court having jurisdiction under the
      Civil Procedure Act.
      <br />
      <small>&#10113;</small> The laws of Korea shall apply to the lawsuits
      filed by and between the “website” and the users.
      <br />
    </p>
  ),
};

const privacy = {
  header: "Privacy Policy",
  title: "Virtual Seoul Privacy Policy",
  content: (
    <p>
      The Seoul Tourism Organization (hereinafter referred to as “Organization”
      or “we” or “us” or “our”), hereby stipulates Privacy Policy (hereinafter
      referred to as this “Privacy Policy”) as the basic matters necessary to be
      observed in order to properly manage and operate Personal Information
      obtained by us through “Virtual Seoul” service (hereinafter referred to as
      the “Services”) provided by organization, and shall handle Personal
      Information properly.
      <br />
      <br />
      <b>1. Obtaining Personal Information</b>
      <br />
      In this Privacy Policy, “Personal Information” means personal information
      defined in Article 2, Paragraph 1 of Act on the Protection of Personal
      Information (Act No. 57 of 2003). Upon obtaining Personal Information, we
      shall explicitly specify the purpose of obtaining such Personal
      Information within the scope of our legitimate business in advance and
      shall obtain and use it in a lawful and fair manner to the extent
      necessary for achieving such purpose.
      <br />
      <br />
      <b>2. Purpose of Use of Personal Information</b>
      <br />
      We shall use Personal Information obtained in the Services only within the
      scope of the following purposes of use. In the event of using Personal
      Information beyond the scope of the purpose of use, we shall obtain the
      prior consent of the person. We may change the purpose of use of Personal
      Information to the extent reasonably deemed to be connected with the
      purpose of use before the change, and if the purpose of use is changed,
      then we shall promptly give a notice or issue an official announcement of
      such change.
      <br />
      &emsp; 1. For provision of the Services;
      <br />
      &emsp; 2. For improvement of convenience of the Services;
      <br />
      &emsp; 3. For collecting statistical data relating to the use of the
      Services, and doing marketing;
      <br />
      &emsp; 4. In order to refuse the use of the Services by those who have
      violated the terms of use or who intend to use the Services for illegal or
      improper purposes;
      <br />
      &emsp; 5. For response to inquiries and identification;
      <br />
      &emsp; 6. For any other communications as needed upon using the Services
      such as notices related to the Services;
      <br />
      &emsp; 7. For the exercise of rights and the performance of obligations
      under the agreements or laws, etc.
      <br />
      <br />
      <b>3. Disclosure of Personal Information</b>
      <br />
      We shall not disclose or provide Personal Information to any third party
      without obtaining consent from the person unless it is permitted to
      disclose or provide those under Act on the Protection of Personal
      Information (Act No. 57 of 2003) or other laws and regulations.
      <br />
      <br />
      <b>4. Inspection, Revision, etc., of Personal Information</b>
      <br />
      When we are requested by the person to disclose Personal Information or to
      revise the incorrect information, we shall promptly respond thereto after
      confirmation of identity. Please contact us at the contact point detailed
      at the end of this page. In addition, when we are requested by the person
      to cease using or to delete Personal Information, we promptly conduct
      necessary inspections, and if we are handling such Personal Information
      beyond the scope of the purpose of use or if find that we have obtained
      such Personal Information by an inappropriate manner, then we shall, upon
      request by the person, cease using or delete such Personal Information.
      <br />
      However, we may refuse the request that we deem unreasonable, such as
      request that is unreasonably repeated, that requires excessive technical
      efforts, and that may harm the privacy of others.
      <br />
      <br />
      <b>5. Cookies and IP Address</b>
      <br />
      When users use the Services, we save and reference cookies and
      automatically obtain and record access logs (histories) such as domain
      name, IP address, pages viewed, and usage environmental information. We
      will use the information obtained through the method above, to make the
      Services a better service, to exploit those as materials for consideration
      of new services, and to analyze what kind of marketing, information, and
      service, etc., are effective to be listed or provided and provide the
      result thereof to event hosts, information providers, etc. Any personally
      identifiable information is never included in the result of the analysis.
      <br />
      <br />
      <b>6. Entrustment of Personal Information Handling</b>
      <br />
      We may outsource the handling of Personal Information for the sake of our
      business operations. In such cases, we shall supervise the trustee and
      cause the trustee to manage Personal Information properly.
      <br />
      <br />
      <b>7. Use of Statistical Data</b>
      <br />
      We may, based on provided Personal Information, produce statistical data
      that processed to make those not personally identifiable. We shall be
      entitled to use the statistical data processed so as not personally
      identifiable, at our sole discretion.
      <br />
      <br />
      <b>8. Safety Management System</b>
      <br />
      We shall conduct education and training, etc., to all employees, including
      our executives, in order to protect Personal Information from leakage,
      loss, damage or otherwise. In addition, in a way of system as well, we
      shall take measures necessary and appropriate to ensure the safety
      management of Personal Information, such as restricting access to files
      include Personal Information, implementing security measures to prevent
      recording access logs and unauthorized access from outside.
      <br />
      <br />
      <b>9. Scope of this Privacy Policy</b>
      <br />
      This Privacy Policy shall not apply to our alliance partners,
      subcontractors, or websites linked to on the websites operated by us, etc.
      We shall have no liability for any trouble occurred on such websites, etc.
      Please confirm the handling of Personal Information on the respective
      websites.
      <br />
      <br />
      <b>10. Revision of this Privacy Policy</b>
      <br />
      We shall review the operation status concerning the handling of Personal
      Information from time to time and endeavor to continuously improve it, and
      this Privacy Policy may be revised as needed. We will notify you of any
      changes in this Privacy Policy by means of notifying individually or
      announcing in the Services.
      <br />
      <br />
      <b>11. Contact Point</b>
      <br />
      With respect to questions, complaints or any other inquiries concerning
      our handling of Personal Information, please contact us through the
      contact point below. [Contact information for Personal Information]
      Department in charge of Seoul Tourism Organization E-mail: vsp@sto.or.kr
      Enacted and enforced on October 1, 2021.
    </p>
  ),
};

// const marketing = {
//   header: "PR/Marketing Promotion",
//   title: "[Personal Information Policy Agreement]",
//   content: `[Pesonal Information Policy Agreement] 2021 Virtual MICE Show
//     (2021 VMS) values the protection and privacy of the participant’s
//     personal information in accordance with the Personal Information
//     Protection Act of Korea. 1. Purpose of Collection and usage
//      • Management of the Virtual MICE Show and participants 2.
//     Collected Information  • Personal Information   - Name, Phone
//     Number, E-mail, Country, Company Name, Business Field, etc.
//      • Following information may be automatically collected while
//     using service in relation to the event   - Connection IP
//     information, cookies, service usage records, connection logs, etc.
//     3. Duration of Retaining and Using Personal Information
//      • Personal information is stored from the date of signing up for
//     registration. It is retained and used only when 2021 Virtual MICE
//     Show Secretariat provides any services in relation to the event.
//      • Personal information can be immediately deleted when   -
//     requesting withdrawal from the participation,   - requesting
//     withdrawal from the previous agreement on collecting and using
//     personal information,   - once the purpose of collection and use
//     of the personal information is achieved,   and ends the period of
//     retention and use`,
// };

export default {
  user,
  privacy,
  // marketing,
};
