import React, { useRef, useContext } from "react";
import styled from "styled-components";

const Btn = styled.button`
  @keyframes lobby-banner-slideshow-fade {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    7% {
      opacity: 1;
      pointer-events: auto;
    }
    33% {
      opacity: 1;
      pointer-events: auto;
    }
    40% {
      opacity: 0;
      pointer-events: none;
    }
    100% {
      opacity: 0;
      pointer-events: none;
    }
  }

  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  animation-name: lobby-banner-slideshow-fade;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-delay: ${(props) => props.animationDelay};
`;

const Slideshow = ({ list, onClick }) => {
  return list.map((el, idx) => {
    const { imgUrl, type, val } = el;
    return (
      <Btn
        onClick={() => {
          onClick(type, val);
        }}
        key={idx}
        animationDelay={idx * 4 + "s"}
      >
        <img alt="좌측 중간 배너" src={imgUrl} width="100%" height="100%" />
      </Btn>
    );
  });
};

export default Slideshow;
