import "moment-timezone";
import React, { useState } from "react";

const Session = ({ session, showProfile, openDocument, handleOpenVod }) => {
  const {
    idx,
    type,
    title,
    open_time,
    close_time,
    place_type,
    moderatorList,
    presentationList,
  } = session;
  const isNotSessionType = ["OP", "SP"].includes(type);
  const classNamePrefix = isNotSessionType ? "programContent" : "programTitle";

  return (
    <React.Fragment key={idx}>
      {!isNotSessionType &&
        presentationList?.map((p) => {
          const {
            idx,
            title: p_title,
            download_url,
            speakerList,
            start_time,
            end_time,
            abstract,
          } = p;
          return (
            <li key={idx} className="programContent pt">
              <div className="programContent__cell">
                <p>
                  {start_time} - {end_time}
                </p>
              </div>
              <div className="programContent__cell">
                <p className="tit">{p_title}</p>
                {speakerList && (
                  <small className="panel">
                    {speakerList?.map((s) => {
                      const { name, organization, job_title, country } = s;
                      s.abstract = abstract;
                      const _showProfile = () => showProfile(s);
                      return (
                        <a
                          key={name}
                          className="moderator__active"
                          onClick={_showProfile}
                        >
                          {name} ({organization}, {job_title}, {country}) &nbsp;
                        </a>
                      );
                    })}
                  </small>
                )}
              </div>
              <div className={`${classNamePrefix}__cell--selected`}>
                <a
                  style={{ width: "100%", height: "100%" }}
                  onClick={() =>
                    // 230719 김민성 - 항시 입장 가능하게 수정

                    {
                      handleOpenVod(p);
                    }
                  }
                >
                  Enter
                </a>
              </div>
              <div className="programContent__cell">
                {download_url && (
                  <button
                    type="button"
                    className="download-docs"
                    data-url={download_url}
                    onClick={openDocument}
                  >
                    <ion-icon name="document-attach-outline"></ion-icon>
                  </button>
                )}
              </div>
            </li>
          );
        })}
    </React.Fragment>
  );
};

export default Session;
