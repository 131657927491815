import React from "react";

const FrontCardBox = ({ name, local, photo, cardBg }) => {
  const { kr, en } = name.current;
  return (
    <div
      id="hiddenCardFront"
      style={{
        width: "960px",
        height: "610px",
        background: "transparent",
        position: "fixed",
        top: "0px",
        left: "-1000px",
        zIndex: 1000000000000000000,
      }}
    >
      <img
        style={{ position: "relative", fontSize: "14px" }}
        src={cardBg}
        crossOrigin="anonymous"
      ></img>
      <p
        style={{
          margin: "0",
          color: "#666",
          fontSize: "20px",
          fontWeight: 300,
          letterSpacing: "unset",
          position: "absolute",
          top: "39rem",
          left: "34rem",
          border: "1px solid #fff",
          width: "600px",
          letterSpacing: "-1px",
        }}
        id="Date"
      >
        Date of issue: {local}
      </p>

      {photo && (
        <img
          style={{
            height: "258px",
            width: "226px",
            position: "relative",
            top: "-446px",
            left: "50px",
            border: "2px solid rgb(51, 70, 139)",
            borderRadius: "11px",
          }}
          className="photo-preview"
          crossOrigin="anonymous"
          src={photo}
        />
      )}

      <p
        style={{
          position: "absolute",
          top: "219px",
          left: "34rem",
          color: "#29294b",
          fontSize: "18px",
          lineHeight: "4rem",
        }}
      >
        Name
      </p>

      {kr && (
        <div
          className="event-name"
          style={{
            top: "219px",
            position: "absolute",
            left: "41rem",
            width: "47rem",
          }}
        >
          <p
            id="koreanName"
            style={{
              textAlign: "start",
              margin: kr.length > 8 ? "-1px 0 15px 0" : "-1px 0 15px 0",
              color: "#33458a",
              fontSize: kr.length > 8 ? "39px" : "48px",
              fontFamily: `"Noto Sans KR", sans-serif`,
              fontWeight: "700",
            }}
          >
            {kr}
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: "500",
              textAlign: "start",
              color: "#293870",
              fontSize: "26px",
            }}
          >
            {en}
          </p>
        </div>
      )}
    </div>
  );
};

export default FrontCardBox;
