import React, { useEffect } from "react";
import CommentBox from "../commentbox/CommentBox";
import { addStatisticPresentationVisit } from "../../../../../query/queries";
import { useMutation, gql } from "@apollo/client";

const ADD_STATISTIC_PRESENTATION_VISIT = gql`
  ${addStatisticPresentationVisit}
`;

const LiveStreaming = ({ presentation, user, refresh, close }) => {
  const [addStatisticPresentationVisit] = useMutation(
    ADD_STATISTIC_PRESENTATION_VISIT
  );
  const { idx, title, abstract, action_url } = presentation;
  useEffect(() => {
    addStatisticPresentationVisit({ variables: { presentation_idx: +idx } });
  }, []);

  const isLoggedIn = user && Object.keys(user).length;
  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          {/* <button className="reload" onClick={refresh}>
            <ion-icon name="refresh"></ion-icon>
          </button> */}

          <section className="modal__tit">
            <h2>{title}</h2>
          </section>
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
        </div>
        <div className="modal__content live-session">
          <div
            style={
              !isLoggedIn
                ? {
                    gridTemplateColumns: "100%",
                  }
                : {}
            }
            className="content-box"
          >
            <div className="live-session__content">
              <div className="live-session__video">
                {action_url ? (
                  <iframe
                    title="video"
                    width="100%"
                    height="100%"
                    src={action_url}
                    preload="auto"
                    className="embed-responsive-item"
                    allow="camera; microphone; autoplay; fullscreen"
                  ></iframe>
                ) : (
                  <p>STREAMING URL NOT FOUND</p>
                )}
              </div>
              {title && abstract && (
                <div className="live-session__details">
                  <p className="tit">{title}</p>
                  <span className="desc">{abstract}</span>
                </div>
              )}
            </div>
            {isLoggedIn && <CommentBox roomCode={idx} roomTitle={title} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveStreaming;
