import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import ProgramInfo from "../../components/common/modal/contents/programInfo/ProgramInfo";
import define from "../../config/define";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import useResize from "../../hooks/useResize";
import { conference2D } from "../../query/queries";
import PulsingAnimation from "../../components/common/pulsingAnimation/PulsingAnimation";

const CONFERENCE_INFO = gql`
  query ($idx: Int!, $language: LANGUAGE_TYPE) {
    event(idx: $idx, language: $language) {
      ${conference2D}
    }
  }
`;

const ScreenOpener = styled.div`
  width: 550px;
  height: 334px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 362px;
  display: block;
  cursor: pointer;
  left: 680px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
    bottom: 76%;
  }
`;
const LeftBanner = styled.div`
  width: 214px;
  height: 645px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 68px;
  display: block;
  cursor: pointer;
  left: 261px;
  z-index: 2;
  color: transparent;
`;
const RightBanner = styled.div`
  width: 213px;
  height: 644px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 68px;
  display: block;
  cursor: pointer;
  left: 1446px;
  z-index: 2;
  color: transparent;
`;
const TopBanner = styled.div`
  width: 904px;
  height: 220px;
  font-size: 25px;
  // background-color: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 78px;
  display: block;
  cursor: pointer;
  left: 508px;
  z-index: 2;
  color: transparent;
`;

const Conference = ({
  mainLang,
  selectedPT,
  pt_survey_active,
  showFuncCollection,
  timeZone,
  scheduleList,
}) => {
  const contentWrapRef = useRef(null);
  const { contentWrapStyle, contentResizeStyle } = useResize(contentWrapRef);
  const { triggerModal } = useContext(ModalPortalContext);
  const { user } = useContext(UserLoginContext);
  const userInfo = useRef(null);
  const { error, data: conferenceData } = useQuery(CONFERENCE_INFO, {
    variables: {
      // event_idx: eventIdx,
      // language: mainLang,
      idx: 36,
    },
    onError: (err) => {
      console.log("error", error);
      console.log("err", err);
    },
    onCompleted: () => {
      console.log("conferenceData", conferenceData);
      //conferenceData.event.conferenceSet
    },
  });

  const conferenceSet = conferenceData?.event?.conferenceSet;
  // const {
  //   left_banner_type,
  //   left_banner_val,
  //   right_banner_type,
  //   right_banner_val,
  //   top_banner_type,
  //   top_banner_val,
  //   main_screen_cover,
  //   left_banner,
  //   right_banner,
  //   top_banner,
  // } = conferenceSet;

  const runByBannerType = (somethings_banner_type, somethings_banner_val) => {
    if (somethings_banner_type === "event") {
      const eventUrl = somethings_banner_val;
      const type = somethings_banner_type;
      // showFuncCollection?.showEvent(eventUrl);// 이벤트 모달은 이미지용
      showFuncCollection?.showSurvey(eventUrl, type); // 서베이 모달은 임베디드 url용
    } else if (somethings_banner_type === "survey") {
      const surveyUrl = somethings_banner_val;
      showFuncCollection?.showSurvey(surveyUrl);
    } else if (somethings_banner_type === "membership") {
      showFuncCollection?.showMembership();
    } else if (somethings_banner_type === "vod") {
      showFuncCollection?.showReplay();
    } else if (somethings_banner_type === "networking") {
      showFuncCollection?.showNetworking();
    } else if (somethings_banner_type === "program") {
      showFuncCollection?.showProgramInfo();
    } else if (somethings_banner_type === "link") {
      showFuncCollection?.goTo(somethings_banner_val);
    }
  };

  const screenOpener = () => {
    triggerModal({
      bool: true,
      props: {
        scheduleList,
        user,
        pt_survey_active,
      },
      content: {
        title: "PROGRAM INFO",
        Component: (props) => {
          return <ProgramInfo {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          background:
            "linear-gradient(rgb(4, 16, 21) 0%, rgb(12, 41, 54) 100%)",
        }}
      >
        <div
          className="lobby wrapper login-wrap"
          style={{
            height: "100%",
            position: "relative",
            background: "none",
          }}
        >
          <div className="content-wrap" style={contentWrapStyle}>
            <div
              className="canvas-wrap"
              ref={contentWrapRef}
              style={contentResizeStyle}
            >
              <img
                alt="conference BG image"
                src={define.RESOURCE.ConferenceHall.Style1}
                className="lobby__background"
              />
              <ScreenOpener
                title="Screen Opener"
                type="button"
                onClick={screenOpener}
              >
                <img
                  alt="main screen cover"
                  src={
                    selectedPT.cover_url
                      ? selectedPT.cover_url
                      : conferenceSet?.main_screen_cover
                  }
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </ScreenOpener>
              <LeftBanner
                onClick={() =>
                  runByBannerType(
                    conferenceSet?.left_banner_type,
                    conferenceSet?.left_banner_val
                  )
                }
              >
                <img
                  alt="left banner"
                  src={
                    conferenceSet?.left_banner ||
                    "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                  }
                  width="100%"
                  height="100%"
                />
              </LeftBanner>
              <RightBanner
                onClick={() =>
                  runByBannerType(
                    conferenceSet?.right_banner_type,
                    conferenceSet?.right_banner_val
                  )
                }
              >
                <img
                  alt="right banner"
                  src={
                    conferenceSet?.right_banner ||
                    "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                  }
                  width="100%"
                  height="100%"
                />
              </RightBanner>
              <TopBanner
                onClick={() =>
                  runByBannerType(
                    conferenceSet?.top_banner_type,
                    conferenceSet?.top_banner_val
                  )
                }
              >
                <img
                  alt="top banner"
                  src={
                    conferenceSet?.top_banner ||
                    "data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                  }
                  width="100%"
                  height="100%"
                />
              </TopBanner>
              {/* <Handlers /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conference;
