// 로딩화면
export const loadingSet = `
    loadingSet {
        background_img,
        left_logo_yn,
        right_logo_yn,
        left_logo,
        right_logo,
        message
    }
`;

export const lobbySet = `
    lobbySet {
        name
        board_logo
        video_cover
        video_url
        big_adballoon
        adballoon_logo1
        adballoon_logo2
        custom_code
        left_horizontal_banner
        right_horizontal_banner
        left_vertical_banner
        right_vertical_banner
        left_horizontal_banner_type
        right_horizontal_banner_type
        left_vertical_banner_type
        right_vertical_banner_type
        left_horizontal_banner_val
        right_horizontal_banner_val
        left_vertical_banner_val
        right_vertical_banner_val

        left_big_banner
        left_big_banner_type
        left_big_banner_val
        left_big_banner2
        left_big_banner2_type
        left_big_banner2_val
        left_big_banner3
        left_big_banner3_type
        left_big_banner3_val
  
        right_big_banner
        right_big_banner_type
        right_big_banner_val
        right_big_banner2
        right_big_banner2_type
        right_big_banner2_val
        right_big_banner3
        right_big_banner3_type
        right_big_banner3_val
    }
`;

// 네비게이션 메뉴 외 정보
export const navSet = `
    navigationSet {
        event_logo,
        names {
            lobby_name
            open_stage_name
            conference_name
            workshop_name
            exhibition_name
            outdoor_name
            business_room_name
            lounge_name
            theater_name
            seoul_booth_name
          }
        guide_url
        guide_yn
        channeltalk_yn
        channeltalk_key
        playground_yn
        stamp_yn
        vod_modal_yn
        networking_modal_yn
        membership_modal_yn
        replay_modal_yn
    }
`;

// 세션 - 컨퍼런스 리스트
export const sessionListQuery = `
    sessionList(language: $language){
        idx,
        code,
        isLang,
        title,
        open_time,
        close_time,
        type,
        place_type,
        presentationList {
          idx,
          session_idx,
          title,
          start_time,
          end_time,
          cover_url,
          type,
          action_url,
          download_url,
          zoom_number,
          zoom_password,
          abstract,
          speakerList {
            name,
            email,
            job_title,
            organization,
            country,
            biodata,
            photo_url,
            role,
          }
        }
        moderatorList {
            name,
            email,
            job_title,
            organization,
            country,
            biodata,
            photo_url,
            role,
        }
  }`;

// 사용 약관, 개인정보 약관
export const consent = `
    query {
        event {
        ...Consent
        }
    }
    fragment Consent on Event {
        privacy_consent
        user_consent
    }
`;

// 컨퍼런스 내부
export const conference = `
    conferenceSet {
        left_banner
        right_banner
        replay_yn
        replay_cover
        profile_yn
        profile_cover
        custom_code
        top_banner
        main_screen_cover
    }
`;
export const conference2D = `
    conferenceSet {
        left_banner
        right_banner
        left_banner_type
        left_banner_val
        right_banner_type
        right_banner_val
        top_banner_type
        top_banner_val
        replay_yn
        replay_cover
        profile_yn
        profile_cover
        custom_code
        top_banner
        main_screen_cover
    }
`;

// 워크샵 내부
export const workshopName = `
    workshopSet {
      center_banner,
      side_banner1,
      side_banner2,
      side_banner3,
      side_banner4
    }
`;

export const replayList = `
    replayList {
        speaker_idx
        title
        action_url
        cover_url
        note
        sort_order
        speaker {
            name
            email
            job_title
            organization
            country
            biodata
            photo_url
            role
        }
    }
`;

// 서울광장 내부
export const openStage = `
    openStageSet {
       adballoon_cover,
       replay_yn,
       replay_cover,
       profile_yn,
       profile_cover,
       left_screen_url,
       right_screen_url
    }
`;

// ddp 극장 내부
export const theater = `
    theaterSet {
        adballoon_left_logo
        adballoon_right_logo
        theaterCategoryList {
            category_name
            cover_url
            sort_order
            theaterVodList {
                name,
                cover_url,
                video_url,
                summary,
                sort_order
            }
        }
    }
`;

export const businessRoom = `
    businessRoomSet {
        screen_video
        left_banner
        right_banner
    }
`;

export const indoorExhibition = `
    exhibitionSet {
        left_spon_banner1
        left_spon_banner2
        left_spon_banner3
        left_spon_banner4
        right_spon_banner1
        right_spon_banner2
        right_spon_banner3
        right_spon_banner4
        booth_image1
        booth_image2
        booth_image3
        booth_image4
        booth_image5
        screen_video
        booth_video1
        booth_video2
        kiosk_cover1
        kiosk_cover2
        kiosk_cover3
        booth_kiosk_link
        site_link
        floor_map
        survey_link
        brochure_url
        sponsorList {
            name
            image_url
            link_url
        }
    }
`;

export const seoulBooth = `
    seoulBoothSet {
        top_roller_banner
        screen_video
        front_left_logo
        front_right_logo
        kiosk_left_cover
        kiosk_right_cover
        kiosk_left_link
        kiosk_right_link
        brochureList {
            download_url
            cover_url
        }
    }
`;

export const seoulLounge = `
    loungeSet {
        name
        survey_url
        zoomUrl
    }
`;

export const boothExhibitionList = `
# Write your query or mutation here
    query BoothExhibitionList {
       boothExhibitionList {
        id
        idx
        name
        custom_code
        companyList{
          website_url
          sort_order
          companyBooth{
            company_idx
            commerce_link
            logo_url
            exhibition_logo_url
            video_url1
            booth_banner
            booth_title
            brochure_top_cover_image
            brochure_bottom_cover_image
          }
        }
      }
    }
`;

export const companyCategorySetList = `
    query {
        companyCategorySetList {
            categoryList1 {
              idx
              parent_lev
              name
            }
            categoryList2 {
              idx
              parent_lev
              name
            }
            categoryList3 {
              idx
              parent_lev
              name
            }
          }
    }
`;

export const companyBoothSet = `
    query companyBoothSet($company_idx: Int) {
        companyBoothSet(company_idx: $company_idx){
            company_idx
            booth_exhibition_idx
            title_url
            logo_url
            class_type
            commerce_yn
            commerce_link
            video_url1
            video_url2
            survey_url
            product_image1
            product_link1
            product_image2
            product_link2
            product_image3
            product_link3
            product_image4
            product_link4
            product_image5
            product_link5
            company {
              idx
              ceo_name
              address
              map_url
              mobile_no
              logo_url
              website_url
              email
              job_title
              organization
              abstract
              booth_exhibition_idx
              companySnsList {
                  active_yn
                  link_url
                  type
              }
              is_bookmarked
            }
            companyBrochureList {
                title
                download_url
                cover_url
            }
            companyImageList {
                image_url
            }
          }
    }
`;

export const companyBoothSet2D = `
    query companyBoothSet($company_idx: Int) {
        companyBoothSet(company_idx: $company_idx){
            company_idx
            booth_banner
            booth_title
            logo_url
            video_url1
            class_type
            brochure_top_cover_image
            brochure_bottom_cover_image
            companyBrochureList {
              title
              download_url
              cover_url
            }
            company {
              booth_exhibition_idx
              website_url
              logo_url
              organization
              ceo_name
              address
              mobile_no
              email
              abstract
              companySnsList {
                active_yn
                link_url
                type
              }
            }
        }
    }
`;

export const bookmarkList = `
  query bookmarkList($user_idx: Int) {
    bookmarkList(user_idx: $user_idx) {
      idx
      company_idx
    }
  }
`;

export const toggleBookmark = `
  mutation toggleBookmark($company_idx: Int) {
    toggleBookmark(company_idx: $company_idx) {
      idx
      user_idx
      company_idx
    }
  }
`;

// 스탬프 기록
export const stampSet = `
  query {
      userStamp {
        lobby_vod
        lobby_enter
        conference_screen
        conference_replay
        open_stage_screen
        workshop_screen
        open_stage_replay
        exhibition_booth_visit
        booth_meeting
        nodeul_booth_visit
        business_meeting
        lounge_zoom
        lounge_survey
        theater_vod
        seoul_booth_brochure
        event_image
      }
  }
`;

// 스탬프 기록 요청
export const onStamp = `
    mutation onStamp($type: STAMP_TYPE!){
        onStamp(type: $type){
            lobby_enter
            lobby_vod
            conference_screen
            conference_replay
            open_stage_screen
            open_stage_replay
            workshop_screen
            exhibition_booth_visit
            booth_meeting
            nodeul_booth_visit
            business_meeting
            lounge_zoom
            lounge_survey
            theater_vod
            seoul_booth_brochure
        }
      }
`;

export const addStatisticVisit = `
  mutation {
    addStatisticVisit {
      event_idx
    }
  }
`;

export const addStatisticCompanyVisit = `
  mutation ($company_idx: Int!){
    addStatisticCompanyVisit(company_idx:$company_idx) {
      event_idx
    }
  }
`;

export const addStatisticPresentationVisit = `
  mutation ($presentation_idx: Int!){
    addStatisticPresentationVisit(presentation_idx:$presentation_idx) {
      event_idx
    }
  }
`;

export const event = `
  query ($language: LANGUAGE_TYPE) {
    event (language: $language) {
      loginSet {
        idx
        event_idx
        organization_yn
        name_yn
        mobile_no_yn
        custom_input_yn
        custom_input_name
      }
    }
  }
`;
