import { gql, useLazyQuery } from "@apollo/client";
import React, { useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { virtualPlaceNavs } from "../../config/virtualPlaceNavigation";
import useResize from "../../hooks/useResize";
import define from "../../config/define";
import { lobbySet } from "../../query/queries";
import { ModalPortalContext } from "../../context/ModalContext";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import PulsingAnimation from "../../components/common/pulsingAnimation/PulsingAnimation";
import TheaterVodList from "../../components/theater/TheaterVodList";
import VOD from "../../components/theater/VOD";
import SessionReplay from "../../components/common/modal/contents/SessionReplay";

const Video = styled.div`
  width: 413px;
  height: 260px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: -4px;
  display: block;
  cursor: pointer;
  left: 712px;
  z-index: 2;
  color: transparent;
`;
const ConferenceHallPusher = styled.div`
  width: 450px;
  height: 300px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 350px;
  display: block;
  cursor: pointer;
  left: 735px;
  .pulsing_animation {
    position: relative;
    left: 50%;
    top: 10%;
  }
`;
const TrainningCenterOpener = styled.div`
  width: 225px;
  height: 132px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 541px;
  display: block;
  cursor: pointer;
  left: 1244px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
    top: 100%;
  }
`;

const VODRoomOpener = styled.div`
  width: 225px;
  height: 132px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 541px;
  display: block;
  cursor: pointer;
  left: 1480px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
    top: 100%;
  }
`;

const LeftHorizontalBanner = styled.div`
  width: 261px;
  height: 97px;
  font-size: 25px;
  position: absolute;
  top: 62px;
  display: block;
  cursor: pointer;
  left: 248px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightHorizontalBanner = styled.div`
  width: 260px;
  height: 97px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 62px;
  display: block;
  cursor: pointer;
  left: 1411px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const LeftBigBanner = styled.div`
  width: 478px;
  height: 303px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 200px;
  display: block;
  cursor: pointer;
  left: 111px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightBigBanner = styled.div`
  width: 478px;
  height: 303px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 200px;
  display: block;
  cursor: pointer;
  left: 1331px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const LeftVerticalBanner = styled.div`
  /* background-color: rgba(255, 0, 0, 0.5); */
  width: 114px;
  height: 158px;
  font-size: 25px;
  position: absolute;
  top: 748px;
  display: block;
  cursor: pointer;
  left: 375px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
  }
`;
const RightVerticalBanner = styled.button`
  /* background-color: rgba(255, 0, 0, 0.5); */
  width: 114px;
  height: 158px;
  font-size: 25px;
  position: absolute;
  top: 704px;
  display: block;
  cursor: pointer;
  left: 1401px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: absolute;
    left: 40%;
  }
`;

const ExhibitionHallPusher = styled.div`
  width: 450px;
  height: 127px;
  font-size: 25px;
  /* background-color: rgba(255, 0, 0, 0.5); */
  position: absolute;
  top: 543px;
  display: block;
  cursor: pointer;
  left: 215px;
  z-index: 2;
  color: transparent;
  .pulsing_animation {
    position: relative;
    left: 50%;
    top: 100%;
  }
`;

const Lobby = ({
  isLang,
  loginType,
  names,
  error: lobbySetError,
  lobbySet: lobbyData,
  eventInfoCompleted,
  setSelectedSession,
  setStamp,
  selectedPT,
  showFuncCollection,
  replayList,
  theaterCategoryList,
  navigationSet,
}) => {
  const contentWrapRef = useRef(null);
  const { contentWrapStyle, contentResizeStyle } = useResize(contentWrapRef);
  const history = useHistory();
  // console.log("lobbyData", lobbyData);
  const { triggerModal } = useContext(ModalPortalContext);

  const {
    left_horizontal_banner,
    left_big_banner,
    left_vertical_banner,
    right_big_banner,
    right_horizontal_banner,
    right_vertical_banner,
    video_url,
    right_horizontal_banner_type,
    right_vertical_banner_type,
    right_big_banner_type,
    left_horizontal_banner_type,
    left_big_banner_type,
    left_vertical_banner_type,
    right_horizontal_banner_val,
    right_vertical_banner_val,
    right_big_banner_val,
    left_horizontal_banner_val,
    left_big_banner_val,
    left_vertical_banner_val,
  } = lobbyData;

  const runByBannerType = (somethings_banner_type, somethings_banner_val) => {
    if (somethings_banner_type === "event") {
      const eventUrl = somethings_banner_val;
      const type = somethings_banner_type;
      // showFuncCollection?.showEvent(eventUrl);// 이벤트 모달은 이미지용
      showFuncCollection?.showSurvey(eventUrl, type); // 서베이 모달은 임베디드 url용
    } else if (somethings_banner_type === "survey") {
      const surveyUrl = somethings_banner_val;
      showFuncCollection?.showSurvey(surveyUrl);
    } else if (somethings_banner_type === "membership") {
      showFuncCollection?.showMembership();
    } else if (somethings_banner_type === "replay") {
      showFuncCollection?.showReplay();
    } else if (somethings_banner_type === "vod") {
      showFuncCollection?.showVod();
    } else if (somethings_banner_type === "networking") {
      showFuncCollection?.showNetworking();
    } else if (somethings_banner_type === "program") {
      showFuncCollection?.showProgramInfo();
    } else if (somethings_banner_type === "link") {
      showFuncCollection?.goTo(somethings_banner_val);
    }
  };

  const videoOpener = async () =>
    triggerModal({
      bool: true,
      props: {
        selectedVod: {
          title: "SCREEN VIDEO",
          url: video_url,
        },
      },
      content: {
        title: "",
        Component: (props) => {
          return <VideoOnly {...props} />;
        },
        hasOwnContainer: true,
      },
    });

  const showTrainningCenter = () => {
    const play = (categoryName = "", videoInfo, videoList) => {
      triggerModal({
        bool: true,
        props: {
          onPrev: showTrainningCenter,
          // enabledMeshes.SeoulTheater_Screen01.callback(
          //   categoryName,
          //   videoList
          // ),
          play,
          videoInfo,
          videoList,
        },
        content: {
          title: categoryName,
          Component: (props) => {
            return <VOD {...props} />;
          },
          hasOwnContainer: true,
        },
      });
    };
    triggerModal({
      bool: true,
      props: {
        categoryList: theaterCategoryList,
        play,
      },
      content: {
        title: "Training Center",
        Component: (props) => {
          return <TheaterVodList {...props} />;
        },
        hasOwnContainer: true,
      },
      hasPrevButton: false,
    });
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          background:
            "linear-gradient(rgb(4, 16, 21) 0%, rgb(12, 41, 54) 100%)",
        }}
      >
        <div
          className="lobby wrapper login-wrap"
          style={{
            height: "100%",
            position: "relative",
            background: "none",
          }}
        >
          <div className="content-wrap" style={contentWrapStyle}>
            <div
              className="canvas-wrap"
              ref={contentWrapRef}
              style={contentResizeStyle}
            >
              <img
                alt="로비 배경 화면"
                src={define.RESOURCE.Lobby.Style4}
                className="lobby__background"
              />
              <ConferenceHallPusher
                title="Conference Hall"
                type="button"
                onClick={() => {
                  history.push(virtualPlaceNavs.conference_name.path);
                }}
              >
                <PulsingAnimation />
              </ConferenceHallPusher>
              <ExhibitionHallPusher
                title="ExhibitionHallPusher"
                type="button"
                onClick={() => {
                  history.push(virtualPlaceNavs.exhibition_name.path);
                }}
              >
                <PulsingAnimation />
              </ExhibitionHallPusher>

              {navigationSet.replay_modal_yn === "Y" && (
                <TrainningCenterOpener
                  title="TrainningCenterOpener"
                  type="button"
                  onClick={() => {
                    showTrainningCenter();
                  }}
                >
                  <PulsingAnimation />
                </TrainningCenterOpener>
              )}

              {navigationSet.vod_modal_yn === "Y" && (
                <VODRoomOpener
                  title="VODRoomOpener"
                  type="button"
                  onClick={() => {
                    runByBannerType("vod");
                  }}
                >
                  <PulsingAnimation />
                </VODRoomOpener>
              )}

              <Video>
                {/* <img alt="video cover" /> */}
                <iframe
                  src={video_url}
                  // src="https://player.vimeo.com/video/631527659?controls=0&autoplay=1&loop=1&autopause=0&muted=1"
                  style={{ width: "120%", height: "120%" }}
                  allow="camera; microphone; autoplay; fullscreen"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
                <div
                  style={{
                    // backgroundColor: "red",
                    position: "relative",
                    top: "-295px",
                    width: "120%",
                    height: "280px",
                    zIndex: "2",
                  }}
                  onClick={videoOpener}
                />
              </Video>
              <LeftHorizontalBanner
                title="LeftHorizontalBanner"
                onClick={() =>
                  runByBannerType(
                    left_horizontal_banner_type,
                    left_horizontal_banner_val
                  )
                }
              >
                <img
                  alt="좌측 상단 배너"
                  src={left_horizontal_banner}
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </LeftHorizontalBanner>
              <LeftBigBanner
                title="LeftBigBanner"
                onClick={() =>
                  runByBannerType(left_big_banner_type, left_big_banner_val)
                }
              >
                <img
                  alt="좌측 중간 배너"
                  src={left_big_banner}
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </LeftBigBanner>
              <LeftVerticalBanner
                title="LeftVerticalBanner"
                onClick={() =>
                  runByBannerType(
                    left_vertical_banner_type,
                    left_vertical_banner_val
                  )
                }
              >
                <img
                  alt="좌측 하단 배너"
                  src={left_vertical_banner}
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </LeftVerticalBanner>
              <RightHorizontalBanner
                title="RightHorizontalBanner"
                onClick={() =>
                  runByBannerType(
                    right_horizontal_banner_type,
                    right_horizontal_banner_val
                  )
                }
              >
                <img
                  alt="우측 상단 배너"
                  src={right_horizontal_banner}
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </RightHorizontalBanner>
              <RightBigBanner
                title="RightBigBanner"
                onClick={() =>
                  runByBannerType(right_big_banner_type, right_big_banner_val)
                }
              >
                <img
                  alt="우측 중간 배너"
                  src={right_big_banner}
                  width="100%"
                  height="100%"
                />
                <PulsingAnimation />
              </RightBigBanner>
              <RightVerticalBanner
                title="RightVerticalBanner"
                disabled={navigationSet.vod_modal_yn === "N"}
                onClick={() =>
                  runByBannerType(
                    right_vertical_banner_type,
                    right_vertical_banner_val
                  )
                }
              >
                <img
                  alt="우측 하단 배너"
                  src={right_vertical_banner}
                  width="100%"
                  height="100%"
                />
                {navigationSet.vod_modal_yn === "Y" && <PulsingAnimation />}
              </RightVerticalBanner>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lobby;
