import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import util from "../../../../../util/index";
import {} from "react";

const COMMENT_BOX_TOKEN = gql`
  query getCommentBoxToken(
    $clientToken: String!
    $roomType: String!
    $roomCode: String!
    $roomTitle: String!
  ) {
    getGoventCommentBoxToken(
      clientToken: $clientToken
      roomType: $roomType
      roomCode: $roomCode
      roomTitle: $roomTitle
    ) {
      commentBoxToken
    }
  }
`;

const CommentBox = ({ roomCode, roomTitle }) => {
  const [getToken, { error, loading, data }] = useLazyQuery(COMMENT_BOX_TOKEN);

  useEffect(() => {
    if (roomCode && roomTitle) {
      getToken({
        variables: {
          roomType: "QA",
          roomCode,
          roomTitle,
          clientToken: util.getCookieValue("clientToken"),
        },
      });
    }
  }, [roomCode, roomTitle]);

  return (
    <div className="live-session__comment-box">
      {!loading && data?.getGoventCommentBoxToken?.commentBoxToken && (
        <iframe
          src={`https://comment.govent.io/?token=${data?.getGoventCommentBoxToken?.commentBoxToken}`}
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      )}
    </div>
  );
};

export default CommentBox;
