import React from "react";

const LoungeUserID = ({ user, close }) => {
  const { name, email, job_title, organization, country } = user;
  return (
    <div className="modal popup profile-info" id="profile-info">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper small zoomIn">
        <div className="profile-info__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="profile-info__tit">
            <small>Name</small>
            <p>{name}</p>
          </section>
        </div>
        <div className="profile-info__content">
          <div className="content-box">
            <dl>
              <dt>E-mail</dt>
              <dd>{email}</dd>
            </dl>
            <dl>
              <dt>Job Title</dt>
              <dd>{job_title}</dd>
            </dl>
            <dl>
              <dt>Organization</dt>
              <dd>{organization}</dd>
            </dl>
            <dl>
              <dt>Country</dt>
              <dd>{country}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoungeUserID;
