import React, { useEffect, useState } from "react";
import { defaultDataIdFromObject, gql, useQuery } from "@apollo/client";
import { PUBLIC_URL } from "../../config/define";
import { stampSet } from "../../query/queries";
import { missionContent } from "./data/missionContent";
import { useHistory } from "react-router";

const stamp = gql`
  ${stampSet}
`;

const StampTour = ({ language, close }) => {
  const [myStamp, setMyStamp] = useState({});
  const [percentage, setPercentage] = useState(0);
  const { loading, data } = useQuery(stamp, {
    onCompleted: () => {
      // console.log("asas");
    },
    onError: (err) => {
      console.error("userStamp Error ::", err);
    },
  });
  const history = useHistory();

  const moveTo = (url) => {
    history.push(`/${url}`);
    close();
  };

  useEffect(() => {
    if (!loading && data) {
      if (data.userStamp) {
        setMyStamp(data.userStamp);
        let all = 0;
        let curr = 0;
        console.log(data.userStamp);
        // console.log(Object.keys(data.userStamp).length);
        Object.keys(data.userStamp).forEach((v, i) => {
          // console.log(data.userStamp[v], i);
          if (
            v !== "__typename" &&
            v !== "event_image" &&
            data.userStamp[v] !== null
          ) {
            all += 1;
            return null;
          }
          if (data.userStamp[v] !== "N") {
            curr += 1;
          }
        });
        console.log(curr, all);
        setPercentage(Math.ceil((curr / all) * 100) || 0);
      }
    }
  }, [loading, data]);

  return (
    <div className="modal">
      <div className="modal__overlay"></div>
      <div className="modal__wrapper wide zoomIn">
        <div className="modal__header">
          <button className="close" onClick={close}>
            <ion-icon name="close"></ion-icon>
          </button>
          <section className="modal__tit">
            <h2>Virtual Stamp Tour</h2>
            <h4>Surprise Gift!</h4>
          </section>
        </div>
        <div className="modal__content hd-dsc">
          <div className="content-box">
            <div className="event-box">
              <div className="event-box__img">
                <img
                  src={`${PUBLIC_URL}/assets/img/event_banner.png`}
                  alt="Giveaway Merchandise"
                />
              </div>
              <div className="event-box__tracking">
                <p>
                  You got <span className="percentage">{percentage}%</span> Of
                  Stamps.
                </p>
              </div>
            </div>
            <ul className="stamp-list">
              <li className="title">
                <dl>
                  <dt>Location</dt>
                  <dd className="txt">Mission Content</dd>
                  <dd className="stamp">Stamp</dd>
                </dl>
              </li>
              {myStamp?.lobby_enter ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("lobby")}>Lobby</dt>
                    <label className="ir_so">로비 최초 입장 시 자동 부여</label>
                    <dd className="txt">
                      {missionContent.lobbyEnter[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.lobby_enter === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.lobby_vod ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("lobby")}>Lobby</dt>
                    <label className="ir_so">
                      로비 전광판 영상 클릭 시 부여
                    </label>
                    <dd className="txt">{missionContent.lobbyVod[language]}</dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.lobby_vod === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.conference_screen ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("conference")}>
                      Conference Hall
                    </dt>
                    <label className="ir_so">컨퍼런스 홀에서 스크린 클릭</label>
                    <dd className="txt">
                      {missionContent.conferenceScreen[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.conference_screen === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.conference_replay ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("conference")}>
                      Conference Hall
                    </dt>
                    <label className="ir_so">
                      컨퍼런스 홀에서 좌측 다시보기 배너에서 업로드된 세션 영상
                      클릭
                    </label>
                    <dd className="txt">
                      {missionContent.conferenceReplay[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.conference_replay === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.open_stage_screen ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("openstage")}>Open Stage</dt>
                    <label className="ir_so">
                      오픈스테이지에서 스크린 클릭
                    </label>
                    <dd className="txt">
                      {missionContent.openStageScreen[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.open_stage_screen === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.open_stage_replay ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("openstage")}>Open Stage</dt>
                    <label className="ir_so">
                      오픈 스테이지에서 좌측 다시보기 배너에서 업로드된 세션
                      영상 클릭
                    </label>
                    <dd className="txt">
                      {missionContent.openStageReplay[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.open_stage_replay === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.board_meeting ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("workshop")}>Some Sevit</dt>
                    <label className="ir_so">
                      보드룸에서 회의 참가(스크린 클릭 또는 분과회의 팝업창에서
                      세션 클릭)
                    </label>
                    <dd className="txt">
                      {missionContent.boardMeeting[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.board_meeting === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.exhibition_booth_visit ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("exhibition/indoor")}>Coex</dt>
                    <label className="ir_so">전시홀에서 전시부스 방문</label>
                    <dd className="txt">
                      {missionContent.exhibitionBoothVisit[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.exhibition_booth_visit === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.booth_meeting ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("exhibition/indoor")}>Coex</dt>
                    <label className="ir_so">
                      전시부스에서 1:1 상담/채팅 기능 사용
                    </label>
                    <dd className="txt">
                      {missionContent.boothMeeting[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.booth_meeting === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.nodeul_booth_visit ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("exhibition/outdoor")}>
                      Nodeul Island
                    </dt>
                    <label className="ir_so">
                      야외 전시관에서 전시부스 방문
                    </label>
                    <dd className="txt">
                      {missionContent.nodeulBoothVisit[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.nodeul_booth_visit === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.booth_meeting ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("exhibition/outdoor")}>
                      Nodeul Island
                    </dt>
                    <label className="ir_so">
                      전시부스에서 1:1 상담/채팅 기능 사용
                    </label>
                    <dd className="txt">
                      {missionContent.boothMeeting[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.booth_meeting === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.business_meeting ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("business")}>
                      Seoul Tourism Plaza
                    </dt>
                    <label className="ir_so">
                      상담장에서 화상회의 진행 (좌/우측 스크린 상관없이)
                    </label>
                    <dd className="txt">
                      {missionContent.businessMeeting[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.business_meeting === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.lounge_zoom ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("lounge")}>Seoul Botanic Park</dt>
                    <label className="ir_so">라운지에서 그룹챗 접속</label>
                    <dd className="txt">
                      {missionContent.loungeZoom[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.lounge_zoom === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.lounge_survey ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("lounge")}>Seoul Botanic Park</dt>
                    <label className="ir_so">
                      라운지에서 서베이 키오스크 클릭
                    </label>
                    <dd className="txt">
                      {missionContent.loungeSurvey[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.lounge_survey === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.theater_vod ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("theater")}>DDP</dt>
                    <label className="ir_so">씨어터에서 영상 시청</label>
                    <dd className="txt">
                      {missionContent.theaterVod[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.theater_vod === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
              {myStamp?.seoul_booth_brochure ? (
                <li>
                  <dl>
                    <dt onClick={() => moveTo("booth")}>Namsan Seoul Tower</dt>
                    <label className="ir_so">
                      홍보부스에서 브로셔 다운로드 또는 열람
                    </label>
                    <dd className="txt">
                      {missionContent.seoulBoothBrochure[language]}
                    </dd>
                    <dd className="stamp">
                      <span
                        className={
                          myStamp?.seoul_booth_brochure === "Y"
                            ? "stamp__item on"
                            : "stamp__item"
                        }
                      ></span>
                    </dd>
                  </dl>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StampTour;
