import { gql } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CompanyInfo from "../../components/exhibition/CompanyInfo";
import ExhibitionNavigator from "../../components/exhibition/ExhibitionNavigator";
import ExhibitorList from "../../components/exhibition/type/ExhibitorList";
import { BoothContext, BoothContextProvider } from "../../context/BoothContext";
import { ModalPortalContext } from "../../context/ModalContext";
import { UserLoginContext } from "../../context/UserLoginContext";
import useResize from "../../hooks/useResize";
import { indoorExhibition } from "../../query/queries";
import VideoOnly from "../../components/common/modal/contents/video/VideoOnly";
import define from "../../config/define";

const Booth01Pusher = styled.div`
  position: absolute;
  top: 178px;
  left: 150px;
  width: 430px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth02Pusher = styled.div`
  position: absolute;
  top: 140px;
  left: 700px;
  width: 400px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth03Pusher = styled.div`
  position: absolute;
  top: 100px;
  left: 1180px;
  width: 400px;
  height: 300px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
`;
const Booth04Pusher = styled.div`
  position: absolute;
  top: 590px;
  left: 60px;
  width: 550px;
  height: 400px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;
const Booth05Pusher = styled.div`
  position: absolute;
  top: 530px;
  left: 750px;
  width: 520px;
  height: 400px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;
const Booth06Pusher = styled.div`
  position: absolute;
  top: 470px;
  left: 1360px;
  width: 500px;
  height: 370px;
  font-size: 25px;
  background-color: rgba(255, 0, 0, 0.5);
  display: block;
  cursor: pointer;
  z-index: 2;
  color: transparent;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
`;

const IndoorExhibition2DStyle3 = ({
  eventInfoCompleted,
  indoorExhibitionSet,
  loginType,
  error: indoorExhibitionSetError,
  boothList,
}) => {
  const { user } = useContext(UserLoginContext);
  // const { boothList, categoryList, companyInfo, refetch } =
  //   useContext(BoothContext);
  const { triggerModal } = useContext(ModalPortalContext);

  useEffect(() => {
    if (indoorExhibitionSetError) {
      setOnLazyLoading(false);
      setIsLoading(false);
      setStamp("exhibition_booth_visit");
    }
  }, [indoorExhibitionSetError]);

  return (
    <>
      {/* <IntroVideoBox>
                <iframe
                  // src={
                  //   "https://player.vimeo.com/video/591978198?autoplay=1&autopause=0&background=1"
                  // }
                  src="https://player.vimeo.com/video/630761787?autoplay=1&autopause=0&background=1"
                  // src={`${lobby_vod_middle.vod_url}?autoplay=1&autopause=0&background=1`}
                  allow="camera; microphone; autoplay"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                <div
                  style={{
                    position: "relative",
                    top: "-226px",
                    height: "226px",
                    zIndex: "2",
                  }}
                  onClick={openIntroVideo}
                />
              </IntroVideoBox> */}
      {/* <Handlers /> */}
      <img
        alt="exhibition BG image"
        src={define.RESOURCE.ExhibitionHall.Style3}
        className="lobby__background"
      />
      <Booth01Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name">
          <p>{}</p>
        </div>
      </Booth01Pusher>
      <Booth02Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name"></div>
      </Booth02Pusher>
      <Booth03Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name"></div>
      </Booth03Pusher>
      <Booth04Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name"></div>
      </Booth04Pusher>
      <Booth05Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name"></div>
      </Booth05Pusher>
      <Booth06Pusher>
        <div className="imageUrl"></div>
        <div className="linkUrl"></div>
        <div className="name"></div>
      </Booth06Pusher>
    </>
  );
};

const IndoorExhibitionContainer = (props) => {
  return (
    <BoothContextProvider>
      <IndoorExhibition2DStyle3 {...props} />
    </BoothContextProvider>
  );
};

export default IndoorExhibitionContainer;
