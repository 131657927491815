/*
project root의 .env 환경변수 참고.
*/
console.log("process.env.PUBLIC_URL ", process.env.PUBLIC_URL);
export const PUBLIC_URL = process.env.PUBLIC_URL;

const define = {
  API_BASE_URL: `${process.env.NEXT_PUBLIC_CLIENT_API_BASE_URL}`,
  RESOURCE: {
    Lobby: {
      Style1: `${PUBLIC_URL}/resources/lobby/LOBBY_2D_01.jpg`,
      Style2: `${PUBLIC_URL}/resources/lobby/LOBBY_2D_02.jpg`,
      Style3: `${PUBLIC_URL}/resources/lobby/LOBBY_2D_03.jpg`,
      Style4: `${PUBLIC_URL}/resources/lobby/LOBBY_2D_04.jpg`,
    },
    ExhibitionHall: {
      Style1: `${PUBLIC_URL}/resources/indoor/ExhibitionHall01.jpg`,
      Style2: `${PUBLIC_URL}/resources/indoor/ExhibitionHall03.jpg`,
      Style3: `${PUBLIC_URL}/resources/indoor/ExhibitionHall02.jpg`,
    },
    Booth: {
      Style1: `${PUBLIC_URL}/resources/booth/Booth_01.jpg`,
      Style2: `${PUBLIC_URL}/resources/booth/Booth_02.jpg`,
      Icon: {
        info: `${PUBLIC_URL}/resources/booth/booth_info_icon.png`,
      },
    },
    ConferenceHall: {
      Style1: `${PUBLIC_URL}/resources/conference/ConferenceHall01.jpg`,
    },
  },
  API: {
    AUTH_LOGIN: {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
    },
  },
};

export default define;
